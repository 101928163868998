Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Sign up";
exports.lblSubHeader = "We couldn't find your email address in our system. let’s create new account."
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelCompanyName = "Company Name";
exports.labelCountry = "Select Country";
exports.labelState = "Select State";
exports.labelCity = "Select City";
exports.labelPostalCode = "Postal Code";
exports.labelCompanyAddress = "Company Address";
exports.cityName = "City";
exports.countryName = "Country";
exports.stateName = "State";
exports.companyAddress = "Billing Address";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.createAccountlabelHeader = "Create Profile";
exports.createAccountlabelHeaderHelperText =
  "Please fill out the following information";
exports.createAccountSubmitBtn = "Next";
exports.errorEmail = "Email should not be blank";
exports.errorEmailNotValid = "Email is not valid.";
exports.errorPassword = "Password should not be blank";
exports.errorPasswordNotValid = "Password does not meet requirements";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.errorFirstnameNotValid = "First name should not be blank";
exports.errorlastNameNotValid = "Last name should not be blank";
exports.errorcompanyNameNotValid = "Please enter your company name";
exports.errorcountryNotValid = "Please select the country";
exports.errorstateNotValid = "Please select the state";
exports.errorcityNotValid = "Please select the city";
exports.errorcompanyaddressNotValid = "Please select the address";
exports.errorLegalCheckbox =
  "Please accept the Privacy Policy and Terms of Use.";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.lableTermsCond = "I agree to the";
exports.lableAnd = "and";

exports.labelTermOfUse = "Terms of Use";
exports.labelPrivacyPolicy = "Privacy Policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";

exports.countryAPIEndPoint = "account_block/country"
exports.createAccountsAPiEndPoint = "account_block/accounts/create_company";
exports.statesAPIEndPoint = "/account_block/state?country_id=";
exports.cityAPIEndpoint = "/account_block/city?state_id=";

exports.labelOr = "or";
exports.btnTxtSocialLogin = "Have an account?";
exports.apiMethodTypeAddDetail = "POST";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.welcomeEtoh = "Welcome to EtOH Suite"
exports.createTeamTitle = "Now, let's add all your team members!"
exports.subTitle = "Manage your teams or add new members."
exports.welcomeLblHeader = "Welcome!"

//===Logout API request Start=====

exports.txtSignOut = "Log Out"
exports.txtAreYouSureSignOut = "Are you sure you want to log out?"

exports.baseURL = require("../../../framework/src/config")
exports.signOutEndPoint = "/account_block/log_out"
exports.methodDELETE = "DELETE"

//===Logout API request End=====
exports.removeTeamHeader = "Remove Team"
exports.removeJobtitleHeader = "Remove Jobtitle"
exports.Email = "Email";
exports.companyName = "Company name";
exports.chooseTeam = "Choose Team";
exports.chooseTeamSmall = "choose Team";
exports.jobType = "Job Type";
exports.jobTitle = "Job Title";
exports.postalCode = "Postal Code";
exports.city = "City";

exports.hourlyPay = "$ Hourly Pay";
exports.txtChooseSubscription = "Choose Subscription";
exports.txtChooseSubscriptionType = "Please choose a subscription type";
exports.txtMonthlyPlan = "Monthly Plan";
exports.txtYearlyPlan = "Yearly Plan";
exports.payPerUser = "30";
exports.txtPayNow = "Pay Now";
exports.txtMembersSuccessfully = "Added Successfully";
exports.txtAddMoreMembers = "You can add more members or you can finish.";
exports.txtAddAnotherMember = "Add Another Member";
exports.txtReturnToTeams = "Return to Teams";

exports.baseURL = require("../../../framework/src/config");
exports.createTeamsEndPoint = "/account_block/multiple_teams"
exports.getJobTitlesEndPoint = "/account_block/title_list"
exports.getCreateJobTitlesEndPoint = "/account_block/title_list";
exports.webCreateJobTitleEndPoint = "/account_block/title_create";
exports.webUpdateJobTitleEndPoint = "/account_block/title_update";
exports.webUpdateTeamEndPoint = "/account_block/team_update";
exports.webEditMemEndPoint ="/account_block/edit_member_details?id="
exports.createNewJobTitleEndPoint = "/account_block/title_create"
exports.getMethodPost = "POST"
exports.renameJobTitleEndPoint = "/account_block/title_update"

exports.requestMethodPost = "POST";
exports.requestMethodGet = "GET";
exports.requestMethodPatch = "PATCH";
exports.requestMethodDelete = "DELETE";
exports.requestMethodPut = "PUT";
exports.txtStartTypingJobTitle = "Start Typing Job Title";
exports.txtCancel = "Cancel";
exports.txtCreate = "Create";

exports.txtUpdateDetailsOfMember = "Update the details of this member.";

// Filters Sheet exports start
exports.txtFilters = "Filters";
exports.txtReset = "Reset";
exports.txtChooseRole = "Choose Role";
exports.txtOwner = "Owner";
exports.txtManager = "Manager";
exports.txtEmployee = "Employee";
exports.txtJobTitle = "Job Title";
exports.txtJobTitleSmall = "Job title";
exports.txtShowDeactivated = "Show only deactivated members";
exports.txtApplyFilters = "Apply Filters";
exports.txtPlaceholderSearchJobTitle = "Search Job Title";
exports.errorHex = "#C7263E";
// Filters Sheet exports end

exports.txtAddMemberTitle = "Select the team member(s) you want to add to this team"
exports.txtCreateTeam = "+ Create Team"	
exports.txtInviteMembers = "+ Invite Members"	
exports.txtNoMemberInThisTeam = "No member in this team"	
exports.txtFetchingMembersList = "Fetching Members list..."
exports.headerAddYourSelf = "Let's add yourself"	
exports.subHeaderAddYourSlef = "Please fill in the details below"	
exports.description = "This is used to calculate labor cost for projects. Only Owners can see this."
exports.webTeamsTitle = "Teams & Members";
exports.webTeamsSubTitle = "Manage your teams or add new members";
exports.EmployeesSubTitle = "View all teams and members.";
exports.webInviteBtn = "Invite Members";
exports.labelwebCreateTeam = "Create a Team";
exports.labelwebCreateTeamSubtxt = " Let's start by creating your first team.";
exports.labelwebCreateFinishtxt = "Finish";
exports.labelwebCreateTeamBtntxt = "Create";
exports.labelwebCreateTeamtxt = "Create Team";
exports.labelwebAnotherTeamtxt = "Add another team";
exports.labelwebRenameTeamBtntxt = "Update";
exports.labelwebRenameTeamtxt = "Rename Team";
exports.labelAddMembersBtntxt = "Add Members";
exports.labelCreateTeamModaltxt = "Add the name of each of your teams";
exports.labelRenameTeamModaltxt = "Please enter the name";
exports.labelDeleteModalBtnTxt = "Delete";
exports.labelDeactivateMemberBtnTxt = "Deactivate";
exports.DeleteModalMessage = "Are you sure you want to delete this team and all its members? Any members not on another team will be added to an 'Unassigned Members' team.";
exports.deactivateModalMessage="Are you sure you want to revoke access to EtoH Suite for Esther Hovard? The user will no longer be able to use the application."
exports.webTeamsGetEndPoint = "account_block/teams";
exports.webMembersListEndPoint = "account_block/member_list";
exports.webTitleListEndPoint = "account_block/title_list";
exports.webEmailValidateEndpoint = "account_block/email_validate";
exports.webInviteMemberListEndPoint = "account_block/invite_member";
exports.webfetchTeamsEndPoint = "/account_block/teams";
exports.webcreateTeamsEndPoint = "/account_block/multiple_teams";
exports.webgetMembersList = "/account_block/member_list";
exports.webUnassignedTeam = "/account_block/no_team_member";
exports.webgetMembersTeamList = "/account_block/member_team_list?id=";
exports.webEditMemberEndPoint = "account_block/update_member?id=";
exports.webDeactivateMemberEndPoint = "account_block/deactivate?id=";
exports.webReactivateMemberEndPoint = "account_block/reactivate?id=";
exports.webUpdateTeamsEndPoint = "/account_block/team_update";
exports.webDeleteTeamsEndPoint = "account_block/team_delete";
exports.webMultipleDeleteEndPoint = "account_block/multiple_delete_team";
exports.webDeleteJobTitleEndPoint = "account_block/jobtitle_destroy";
exports.webSearchTeamEndPoint = "/account_block/team_search?search=";
exports.webMoveMemberEndPoint = "account_block/update_member_team";
exports.webAddMemberTeamEndPoint = "account_block/add_member_to_team";
exports.excludeMemberTeamAPIEndPoint = "/account_block/exclude_team_list?account_id="
exports.webUpdateMemberEmail = "account_block/update_email_member?id=";
exports.webUpdateMemberPassword = "account_block/change_password_member?id=";
exports.webRemoveMemberFromTeam = "account_block/remove_member?ids="
exports.webFilterMembers = "/account_block/filters?"
exports.webExcludeTeamMemberEndPoint = "/account_block/exclude_team_member?id="
exports.RowsPerPage = 10;
exports.webTeamCreatedMessage = "Team Created"
exports.webCreateTeamError = "Team already exists"
exports.webTeamRenamedSuccess = "Team Renamed";
exports.webTeamDeleteSuccess = "Team Deleted";
exports.webMemberEditedSucces = "Member Edited"
exports.webMemberDeactivated = "Member deactivated";
exports.webMemberReActivated = "Member Reactivated";
exports.webMemberMovedSuccess = "Member Moved"
// exports.getJobTitlesEndPoint = "/account_block/title_list"
// exports.createNewJobTitleEndPoint = "/account_block/title_create"
// exports.getMethodPost = "POST"
// exports.renameJobTitleEndPoint = "/account_block/title_update"
// Owner Onboarding page
exports.ownerOnboardAPiEndPoint = "/account_block/owner_onboard";

exports.ownerLabelHeader = "Let's add yourself";
exports.ownerLabelHeaderHelperText = "Please fill in the details below";
exports.ownerTeamNameText = "Team Name";
exports.ownerCreateTeamNameText = "Create new team";
exports.ownerTeamNameHyperText = "No Team found with";
exports.ownerTeamNameErrorText = "Team title cannot be empty";
exports.ownerJobTitleText = "Job Title";
exports.ownerJobTitleHelperText = "Add New job Title";
exports.ownerHourText = "Hourly Pay";
exports.ownerHourHyperText = "This is used to calculate labor cost for projects. This will not be shown to other members.";
exports.ownerButtonText = "Next";
exports.ownerJobTitleError = "Please select or create a job title";
exports.ownerTeamNameError = "Please enter a team name";
exports.ownerHourError = "Please enter the hourly pay";
exports.welcomeTitle = "Nice work adding your first team!";
exports.welcomeHyperTitle = "Now you can add all of your team members one by one or upload in bulk.";
exports.welcomeButtonText = "Add Teams & Members";
exports.undoActionAPIEndPint = "/account_block/undo";
exports.teamSearchFilter = "/account_block/team_search";
exports.addMemberSingleText = "Choose the team you would also like to add this member to";
exports.addMemberMultipleText = "Choose the team you would also like to add these members to";
exports.TeamEndpoint = "/blocks/rest";
exports.getAlUserQuizes = "/bx_block_dashboard/quizzes";
exports.membersTxt = "Members";
exports.membersTxtSingle = "Member";
exports.searchTxt="Search here";
exports.deactivatedOnlyTxt="Deactivated only";
exports.teamTxt="Team";
exports.teamsTxt="Teams";
exports.searchTitleTxt="Search Title";
exports.searchTxtType="Start typing to search...";
exports.ChooseOptionTxt="Choose Option";
exports.sortByTxt="Sort by";
exports.deleteTeamTxt="Delete Team";
exports.noMemberFound="No members found";
exports.addToTeam="Add to Team";
exports.deactivateMemberTxt="Deactivate Member";
exports.moveToTeamTxt="Move to Team";
exports.currentTeamTxt="Current Team";
exports.newTeamTxt="New Team";
exports.unassignedMembersTxt="Unassigned Members";
exports.addNewTeamTxt="Add New Team";
exports.removeMemberTxt="Remove Member";
exports.removeConfirmationStart= "Are you sure you want to remove";
exports.removeConfirmationEnd= "from {{teamName}} team?";
exports.txtRemove= "Remove";
exports.allMenberTxt= "Here are all of your team members";
exports.editProfileTxt= "Edit Profile";
exports.firstNameTxt= "First Name";
exports.lastNameTxt= "Last Name";
exports.changeEmailTxt="Change Email";
exports.changePasswordTxt="Change Password";
exports.businessInfoTxt="Business Information";
exports.saveTxt="Save";
exports.allTems="All teams";
exports.usersTxt="users";
exports.selectMembersTxt="Select Member(s)";
exports.allTeamsSubTxt="Here are all the teams that you have created";
exports.addMemberToTeamTxt="Add Members to team";
exports.inviteMemberTxt="Invite Member";
exports.noJobTitleFound="No Job title found with";
exports.noJobTitleCreated="No job title to create";
exports.deleteMember="Delete Member";
exports.modalConfirmation="Are you sure you want to delete member ?";
exports.modalTeamConfirmation="Are you sure you want to delete team ?";
exports.jobTitleDeletTxt="Delete Job Title";
exports.addAuthorTxt="Add Another";
exports.finishAndPayTxt="Finish & Pay";
exports.confirmAndPay="Confirm & Pay";
exports.chooseTitleTxt="Choose Job Title";
exports.calculateTxt="This is used to calculate labor cost for projects. This will not be shown to other members."
exports.inviteMemeberSubTxt="Please fill in the details for this team member";
exports.confirmationTxt="Are you sure you want to add these members to the team?";
exports.deleteJobTitleConfirmationTxt="Are you sure you want to delete Job title ?";
exports.removeMembersConfirmationTxt="Are you sure you want to remove these members from the {{teamName}} team?"
exports.chooseTeamCountConfirmationTxt = "Please choose the team you want to add the {{count}} members into."
exports.chooseTeamToAddMemberTxt ="Please choose the team to you want to add this member into."
exports.revokeAccessTxt= "Are you sure you want to revoke access to EtOH Suite for {{userName}}? This user will no longer be able to use the application.";
exports.logout="Logout";
exports.personalInfoTxt="Personal Information";
exports.chooseCountryTxt="Choose Country";
exports.chooseStateTxt="Choose State";
exports.chooseCity="Choose City";
exports.errorMsgTxt ="We couldn't find your email address in our system. Let’s create a new account.";
exports.lableOR="or";
exports.backTxt="Back";
exports.skipTxt="Skip";
exports.addManuallyTxt="Add Manually";
exports.bulkUploadTxt="Bulk Upload";
exports.notTemsFondTxt="No teams found";
exports.groupTxt="Group";
exports.selectedTxt="Selected";
exports.actionTxt="Actions";
exports.nameTxt="Name";
exports.renameTeamError="Team already exists";
exports.checkAcountStatus="account_block/accounts/";
exports.updateSkip="account_block/update_skip";
exports.termsAndConditionsEndPoints = "terms_and_conditions";
exports.privacyPolicyEndPoints = "privacy_policy";
// Customizable Area End
