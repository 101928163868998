import React from "react";

import {
  Box,
  Typography,
  Grid,
  // Customizable Area Start
  Button,
  Menu,
  MenuItem,
  Checkbox,
  InputAdornment,
  Select,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Input,
  Tab,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Slider,
  FormGroup,
  ListSubheader,
  Switch,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { TabContext, TabList } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import Categoriessubcategories from "../../categoriessubcategories/src/Categoriessubcategories.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import {
  checkedIcon,
  noInvetoryImg,
  filterIcon,
  exportIcon,
  settingIcon,
  shortImg,
  filterImg,
} from "../../catalogue/src/assets";
import WebLoader from "../../../components/src/WebLoader.web";
import { customStyles } from "../../../components/src/Inventory/inventoryItemStyles.web";
import InventoryItem from "./InventoryItem.web";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  deleteGray,
  copyGray,
  downloadGray,
  noFilterItem,
  favouriteProduct,
  vectorBack,
} from "./assets";
import { Link } from "react-router-dom";
import {
  CheckedFilters,
  FilterSearch,
  NumberFilters,
} from "./InventoryManagementController.web";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import ContactListInventory from "../../../components/src/ChooseContactsInventory.web";
import ExportInventoryModalWeb from "../../../components/src/ExportInventoryModal.web";
import ContactFilterModal from "../../../components/src/ContactFilterModal.web";
// Customizable Area End

import InventoryManagementController, {
  Props,
  configJSON,
} from "./InventoryManagementController.web";

export class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showMoreModal = (classes: any) => {
    return (
      <Modal
        open={this.state.moreFilter.open}
        onClose={this.closeMorefilterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ className: "backdrop-root" }}
        className={classes.modalDialog}
      >
        <Box className={`${classes.modalDialogBox} show-more-dialog`}>
          <Box className="modal-heading">
            <Typography className="modal-title">
              <Box className="search-products">
                <Input
                  value={
                    this.state.filterSearch[
                      this.state.moreFilter.name as keyof FilterSearch
                    ]
                  }
                  data-testId="showmoreSearch"
                  name={this.state.moreFilter.name}
                  onChange={this.handleSearchChange}
                  placeholder={this.t(`${configJSON.filterSearchPlaceholder}`)}
                  type="search"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  disableUnderline
                />
              </Box>
            </Typography>
            <Box className="link-title-outer">
              <List>
                {Object.entries(this.state.moreFilter.data).map(
                  ([key, group]) => {
                    return (
                      <ListItem key={key}>
                        {" "}
                        <Link
                          to="#"
                          className={
                            this.state.moreFilter.id == key
                              ? "title-enabled"
                              : ""
                          }
                          onClick={() => this.scrollToDiv(`div-${key}`)}
                          data-test-id={`scrollMore-${key}`}
                        >
                          {key}
                        </Link>{" "}
                      </ListItem>
                    );
                  }
                )}
              </List>
            </Box>
            <Box className="heading-right">
              <CloseIcon
                className="close-icon"
                onClick={this.closeMorefilterModal}
                data-testId="showMorefilterCloseIcon"
              />
            </Box>
          </Box>
          <Box className="modal-description">
            <Box className="check-box-grid link-title-outer">
              <List>
                {Object.entries(this.state.moreFilter.data).map(
                  ([key, group]) => {
                    const filterName = this.state.moreFilter
                      .name as keyof CheckedFilters;
                    const isDisabled =
                      this.state.moreFilter.id != "" &&
                      this.state.moreFilter.id != key;
                    return (
                      <>
                        <ListItem
                          id={`div-${key}`}
                          className={`filtersFormControl ${
                            isDisabled && "disabled"
                          }`}
                        >
                          <Typography>{key}</Typography>
                        </ListItem>
                        {group.map((item) => (
                          <ListItem
                            className={`filtersFormControl ${
                              isDisabled && "disabled"
                            }`}
                            key={item.id}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon />}
                                  checkedIcon={<CheckBoxIcon />}
                                  checked={this.state.checkedFilters[
                                    filterName
                                  ].includes(item.name)}
                                  onChange={(event) =>
                                    this.handleCheckBoxChange(
                                      this.state.moreFilter.name,
                                      event,
                                      item.name
                                    )
                                  }
                                />
                              }
                              label={item.name}
                            />
                          </ListItem>
                        ))}
                      </>
                    );
                  }
                )}
              </List>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  noItemsDiv = (classes: any) => {
    const isFiltered = this.hasFiltered();
    return (
      <Box className={classes.noInventoryBox}>
        <Box className="noInventoryImg">
          <img
            src={isFiltered ? noFilterItem : noInvetoryImg}
            alt="no inventory img"
          />
        </Box>
        <Box className="noInventoryTxtBox">
          <Typography className="noInventorytxt">
            {this.t(
              `${
                isFiltered
                  ? configJSON.inventoryNoFilterItem
                  : configJSON.noInventoryTxt
              }`
            )}
          </Typography>
          {!isFiltered && (
            <Typography className="noInventorySubtxt">
              {this.t(`${configJSON.inventorySubtxt1}`)}
            </Typography>
          )}
          {!isFiltered && (
            <Typography className="noInventorySubtxt">
              {this.t(`${configJSON.inventorySubtxt2}`)}
            </Typography>
          )}
          <Button
            className={`primary-btn ${classes.primaryButton}`}
            onClick={(event) => this.addInventoryOption(event)}
            data-testId="addInventoryBtn"
          >
            + {this.t(`${configJSON.addBtn}`)}
          </Button>
        </Box>
      </Box>
    );
  };
  chooseContactModal = (classes: any) => {
    return (
      <ContactListInventory
        classes={classes}
        contactLists={this.state.contactLists}
        modalOpen={this.state.chooseContact}
        modalClose={this.closechooseContactModal}
        openFilterModal={this.filterActivityHandler}
        openFavouriteModal={this.addProductsAsFavourites}
        selectedAction={this.handleSelectedLists}
        selectedContacts={this.state.selectedContactLists}
        t={this.t}
        data-test-id="chose-contact-modal"
      />
    );
  };

  activityFilterModal = (classes: any) => {
    return (
       <ContactFilterModal 
       classes={classes} 
       isOpen={this.state.isActiveFilter} 
       onClose={this.filterModalClose}
       onApplyFilters={this.fetchAllContacts}
       contactLists={this.state.contactLists}
       />
    )
  };

  favouriteProductModal = (classes: any) => {
    return (
      <Modal
        open={this.state.isFavouriteProductCreated}
        onClose={this.favouriteProductModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
        className={classes.modalDialog}
      >
        <Box
          className={`${classes.rightModalDialogBox} favourite-product-dialog`}
        >
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Link
                to="#"
                className="close-icon"
                onClick={this.favouriteProductModalClose}
                data-test-id="favouriteProductModalClose"
              >
                <CloseIcon />
              </Link>
            </Box>
            <Box className="modal-description">
              <Box className="favourite-product-wrapper">
                <Box className="project-img">
                  <img src={favouriteProduct} alt="favouriteProduct" />
                </Box>
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.inventoryHead}`)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.inventoryText}`)}
                </Box>
                <Box className="button-wrapper full-btn">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Back to Inventory"
                    onClick={this.backToInventory}
                    data-test-id="backToInventory"
                  >
                    {this.t(`${configJSON.backInvetoryButton}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  showCategoryTabs = (classes:any) => {
    return (
      <Box className={classes.tabWrapperSmallDevice}>
      <TabContext value={this.state.currentCategory}>
        <Box className="tabBottom">
          <TabList
            onChange={this.changeCategoryTabs}
            aria-label="lab API tabs example"
            data-testid="tabChange"
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab
              label={this.t(`${configJSON.catergoryName1}`)}
              value={configJSON.ProductsStaticId.toString()}
            />
            <Tab
              label={this.t(`${configJSON.catergoryName2}`)}
              value={configJSON.ToolsStaticId.toString()}
            />
            <Tab
              label={this.t(`${configJSON.catergoryName3}`)}
              value={configJSON.AssetsStaticId.toString()}
            />
            <Tab
              label={this.t(`${configJSON.catergoryName4}`)}
              value={configJSON.ConsumablesStaticId.toString()}
            />
          </TabList>
        </Box>
      </TabContext>
    </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const getAccordianDetails = (
      name: string,
      search: string,
      placeHolder: string
    ) => {
      let options = this.getOptionsforFilter(name, search);
      let filterName = name as keyof CheckedFilters;
      return (
        <AccordionDetails className="categoryContent">
          <Box className="search-wrapper">
            <Input
              placeholder={
                this.t(`${configJSON.searchTxt}`) + " " + placeHolder
              }
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              disableUnderline
              name={name}
              value={search}
              onChange={this.handleSearchChange}
            />
          </Box>
          <FormControl className="filtersFormControl" component="fieldset">
            {options.slice(0, 5).map((option: any) => {
              return (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      checked={this.state.checkedFilters[filterName].includes(
                        option.name
                      )}
                      onChange={(event) =>
                        this.handleCheckBoxChange(name, event, option.name)
                      }
                      data-testId={`${name}-${option.id}`}
                    />
                  }
                  label={option.name}
                />
              );
            })}
            {options.length > 5 && (
              <Typography
                className="category-options-more"
                onClick={() => this.showMoreModalOpen(name, options)}
                data-test-id="showMoreFilter"
              >
                + {options.length - 5}{" "}
                {this.t(`${configJSON.moreOptionCategory}`)}
              </Typography>
            )}
          </FormControl>
        </AccordionDetails>
      );
    };

    const getNumberAccordionDetails = (name: string) => {
      let filterName = name as keyof NumberFilters;
      let defaultValue = [
        this.state.minMaxValues[filterName][0],
        this.state.minMaxValues[filterName][1],
      ];
      return (
        <AccordionDetails className="categoryContent">
          <FormControl className="filtersFormControl" component="fieldset">
            <Box className="slider-progress">
              <Slider
                value={this.state.numberFilters[filterName] ?? defaultValue}
                onChange={(event: any, newValue: any) =>
                  this.numberChange(name, newValue as number[])
                }
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={defaultValue[0]}
                max={defaultValue[1]}
                data-test-id="numberSlider"
              />
            </Box>
            <Box className="slider-block">
              {/* <Box className="categoryTitle">{name}</Box> */}
              <Box className="slider-input">
                <CustomInputWeb
                  type="text"
                  placeholder="Min"
                  value={this.state.inputValues[filterName][0]}
                  onChange={(event: any) =>
                    this.numberInputMinChange(event, name)
                  }
                  onBlur={(event: any) => this.numberInputBlur(event, name)}
                  data-test-id="numberMinvalue"
                />
                <Box className="slider-middle">To</Box>
                <CustomInputWeb
                  type="text"
                  placeholder="Max"
                  value={this.state.inputValues[filterName][1]}
                  onChange={(event: any) =>
                    this.numberInputMaxChange(event, name)
                  }
                  onBlur={(event: any) => this.numberInputBlur(event, name)}
                  data-test-id="numberMaxvalue"
                />
              </Box>
            </Box>
          </FormControl>
        </AccordionDetails>
      );
    };

    const getProductsFilters = () => {
      return (
        <>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.priceNameTxt}`)}
            </AccordionSummary>
            {getNumberAccordionDetails("price")}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.quantityPlaceHolder}`)}
            </AccordionSummary>
            {getNumberAccordionDetails("quantity")}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.alcoholPlaceholder}`)} %
            </AccordionSummary>
            {getNumberAccordionDetails("alcohol")}
          </Accordion>

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.ibuPlaceholder}`)}
            </AccordionSummary>
            {getNumberAccordionDetails("ibu")}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.srmPlaceholder}`)}
            </AccordionSummary>
            {getNumberAccordionDetails("srm")}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.ogPlaceholder}`)}
            </AccordionSummary>
            {getNumberAccordionDetails("og")}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.fgPlaceholder}`)}
            </AccordionSummary>
            {getNumberAccordionDetails("fg")}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.ebcPlaceholder}`)}
            </AccordionSummary>
            {getNumberAccordionDetails("ebc")}
          </Accordion>

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.varietyPlaceHolder}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "grapeVariety",
              this.state.filterSearch.grapeVariety,
              this.t(`${configJSON.varietyPlaceHolder}`)
            )}
          </Accordion>

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.rawMaterialPlaceHolder}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "rawMaterial",
              this.state.filterSearch.rawMaterial,
              this.t(`${configJSON.rawMaterialPlaceHolder}`)
            )}
          </Accordion>

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.dosagePlaceHolder}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "dosage",
              this.state.filterSearch.dosage,
              this.t(`${configJSON.dosagePlaceHolder}`)
            )}
          </Accordion>

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.prodProcessPlaceholder}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "productionProcess",
              this.state.filterSearch.productionProcess,
              this.t(`${configJSON.prodProcessPlaceholder}`)
            )}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.trophynameLabel}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "trophyName",
              this.state.filterSearch.trophyName,
              this.t(`${configJSON.trophynameLabel}`)
            )}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.categoryText}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "category",
              this.state.filterSearch.category,
              this.t(`${configJSON.categoryText}`)
            )}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.volumePlaceholder}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "containerVolume",
              this.state.filterSearch.containerVolume,
              this.t(`${configJSON.volumePlaceholder}`)
            )}
          </Accordion>

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.appellationLabel}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "appellation",
              this.state.filterSearch.appellation,
              this.t(`${configJSON.appellationLabel}`)
            )}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.appellationTypeLabel}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "appellationType",
              this.state.filterSearch.appellationType,
              this.t(`${configJSON.appellationTypeLabel}`)
            )}
          </Accordion>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.countryLabel}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "country",
              this.state.filterSearch.country,
              this.t(`${configJSON.countryLabel}`)
            )}
          </Accordion>

          {this.state.regionOptions.length > 0 && (
            <Accordion className="filterCategory-Box">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="categoryTitle"
              >
                {this.t(`${configJSON.regionLabel}`)}
              </AccordionSummary>
              {getAccordianDetails(
                "region",
                this.state.filterSearch.region,
                this.t(`${configJSON.regionLabel}`)
              )}
            </Accordion>
          )}
          {this.state.subRegionOptions.length > 0 && (
            <Accordion className="filterCategory-Box">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="categoryTitle"
              >
                {this.t(`${configJSON.subRegionLabel}`)}
              </AccordionSummary>
              {getAccordianDetails(
                "subRegion",
                this.state.filterSearch.subRegion,
                this.t(`${configJSON.subRegionLabel}`)
              )}
            </Accordion>
          )}

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.certificateLabel}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "certificationName",
              this.state.filterSearch.certificationName,
              this.t(`${configJSON.certificateLabel}`)
            )}
          </Accordion>
        </>
      );
    };

    const getConsumablesFilters = () => {
      return (
        <>
          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.targetTxt}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "target",
              this.state.filterSearch.target,
              this.t(`${configJSON.targetTxt}`)
            )}
          </Accordion>

          <Accordion className="filterCategory-Box">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="categoryTitle"
            >
              {this.t(`${configJSON.quantityUnitTxt}`)}
            </AccordionSummary>
            {getAccordianDetails(
              "quantityUnit",
              this.state.filterSearch.quantityUnit,
              this.t(`${configJSON.quantityUnitTxt}`)
            )}
          </Accordion>
        </>
      );
    };

    const getBrand = () => {
      return (
        <Accordion className="filterCategory-Box">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="categoryTitle"
          >
            {this.t(`${configJSON.branPlaceHolder}`)}
          </AccordionSummary>
          {getAccordianDetails(
            "brand",
            this.state.filterSearch.brand,
            this.t(`${configJSON.branPlaceHolder}`)
          )}
        </Accordion>
      );
    };
    const getColor = () => {
      return (
        <Accordion className="filterCategory-Box">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="categoryTitle"
          >
            {this.t(`${configJSON.colorPlaceHolder}`)}
          </AccordionSummary>
          {getAccordianDetails(
            "color",
            this.state.filterSearch.color,
            this.t(`${configJSON.colorPlaceHolder}`)
          )}
        </Accordion>
      );
    };

    const getSpecification = () => {
      return (
        <Accordion className="filterCategory-Box">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="categoryTitle"
          >
            {this.t(`${configJSON.specificationsTxt}`)}
          </AccordionSummary>
          {getAccordianDetails(
            "specification",
            this.state.filterSearch.specification,
            this.t(`${configJSON.specificationsTxt}`)
          )}
        </Accordion>
      );
    };

    const getMaterial = () => {
      return (
        <Accordion className="filterCategory-Box">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="categoryTitle"
          >
            {this.t(`${configJSON.materialTxt}`)}
          </AccordionSummary>
          {getAccordianDetails(
            "material",
            this.state.filterSearch.material,
            this.t(`${configJSON.materialTxt}`)
          )}
        </Accordion>
      );
    };

    const getAreaUnit = () => {
      return (
        <Accordion className="filterCategory-Box">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="categoryTitle"
          >
            {this.t(`${configJSON.areaUnitTxt}`)}
          </AccordionSummary>
          {getAccordianDetails(
            "areaUnit",
            this.state.filterSearch.areaUnit,
            this.t(`${configJSON.areaUnitTxt}`)
          )}
        </Accordion>
      );
    };

    const getVolumeUnit = () => {
      return (
        <Accordion className="filterCategory-Box">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="categoryTitle"
          >
            {this.t(`${configJSON.vloumeUnitTxt}`)}
          </AccordionSummary>
          {getAccordianDetails(
            "vesselMeasurement",
            this.state.filterSearch.vesselMeasurement,
            this.t(`${configJSON.vloumeUnitTxt}`)
          )}
        </Accordion>
      );
    };

    const getFilterAccordion = () => {
      switch (this.state.currentCategory) {
        case configJSON.ProductsStaticId.toString():
          return (
            <>
              {getProductsFilters()}
              {getColor()}
            </>
          );
        case configJSON.ToolsStaticId.toString():
          return (
            <>
              {getBrand()}
              {getColor()}
            </>
          );
        case configJSON.AssetsStaticId.toString():
          return (
            <>
              {getBrand()}
              {getMaterial()}
              {getSpecification()}
              {getVolumeUnit()}
              {getAreaUnit()}
            </>
          );

        case configJSON.ConsumablesStaticId.toString():
          return (
            <>
              {getConsumablesFilters()}
              {getAreaUnit()}
              {getBrand()}
              {getColor()}
            </>
          );
      }
    };

    let textToShow = this.t(`${configJSON.catergoryName1}`);
    switch (this.state.currentCategory) {
      case configJSON.ToolsStaticId.toString():
        textToShow = this.t(`${configJSON.catergoryName2}`);
        break;
      case configJSON.AssetsStaticId.toString():
        textToShow = this.t(`${configJSON.catergoryName3}`);
        break;
      case configJSON.ConsumablesStaticId.toString():
        textToShow = this.t(`${configJSON.catergoryName4}`);
        break;
    }
    let displayText;

    if (this.state.checkedItems.length === 0) {
      displayText = `${this.filterItem().length} ${textToShow}`;
    } else if (this.state.checkedItems.length === this.state.selectedinventoryItems.length) {
      displayText = `${this.state.selectedinventoryItems.length} ${textToShow}`;
    } else {
      displayText = `${this.state.checkedItems.length} ${textToShow}`;
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ConfirmActionModal
          isOpen={this.state.isDeletingProduct}
          handleClose={this.handleDeleteModalClose}
          modalConfirmAction={this.deleteItem}
          deleteTeamId=""
          modalMessage={this.t(`${configJSON.modalConfirmation}`)}
          confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
          modalHeading={this.t(`${configJSON.deleteProduct}`)}
          cancelBtnTxt={this.t(`${configJSON.cancelButton}`)}
          data-test-id="confirmDeleteProduct"
        />
        {this.state.isLoading && <WebLoader />}
        <Box
          className={`wrapper ${classes.wrapper} ${classes.inventoryWrapper}`}
        >
          <GlobalHeaderWeb />
          <Box className={`inner-wrapper ${classes.innerWrapper}`}>
            <Box className={classes.inventoryInnerWrapper}>
              <Box className="main-heading">
                <Box className="header-section">
                  <Box className="heading-left">
                    <Box className="heading-box">
                      <Typography className="heading">
                        {this.t(`${configJSON.InventoryHeading}`)}
                      </Typography>
                      <Box className="mobile-view-buttons">
                        <img
                          src={exportIcon}
                          alt="export inventory"
                          className="iconButtonImg exportIcon"
                        />
                        <img
                          src={settingIcon}
                          alt="settings"
                          className="iconButtonImg"
                          onClick={this.handleCategories}
                        />
                      </Box>
                    </Box>
                    <Typography className="inventory-subtitle-txt">
                      {this.t(`${configJSON.InventorySubtitle}`)}
                    </Typography>
                  </Box>
                  {this.state.checkedItems.length > 0 ? (
                    <Box className="inventory-buttons">
                      <Button
                        className="export-btn"
                        onClick={() => this.chooseContactModalOpen()}
                        data-testId="add-as-favourite"
                      >
                        {this.t(`${configJSON.addasfavorite}`)}
                      </Button>
                      
                        <Button
                          className="export-btn"
                          onClick={this.openSendToContactModal}
                          data-testId="sendToContact"
                        >
                          {this.t(`${configJSON.sendToContactTxt}`)}
                        </Button>
                    </Box>
                  ) : (
                    <Box className="inventory-buttons">
                      <Button
                        className="export-btn"
                        onClick={() => this.openExportModal()}
                        data-test-id="export-inventory-btn"
                      >
                        {this.t(`${configJSON.exportInventoryBtnTitle}`)}
                      </Button>
                      {this.isOwner() && (
                        <Button
                          className="export-btn"
                          onClick={this.handleCategories}
                          data-testId="settingBtn"
                        >
                          {this.t(`${configJSON.settingBtnTitle}`)}
                        </Button>
                      )}
                      {/* categories modal */}
                      <Categoriessubcategories
                        openModal={this.state.isCategoriesModal}
                        closeModal={this.closeCategories}
                        data-testId="categoryModal"
                      />
                      {/* exports modal */}
                      {this.state.isExportModalOpen && 
                        <ExportInventoryModalWeb
                        modalOpen={this.state.isExportModalOpen}
                        closeModal={this.closeExportModal}
                        t={this.t}
                        modalTitle={configJSON.exportInventoryTitle}
                        modalDescription={configJSON.exportInventorySubTitle}
                        downloadHandler={this.exportInventoryDownload}
                        downloadBtnText={configJSON.exportInventoryBtnDownload}
                        data-test-id="export-inventory-modal"
                        />
                      }
                      
                    </Box>
                   )}
                 
                </Box>
              </Box>
              <Box className={classes.inventoryContentWrapper}>
                <Box className="mobile-header-section">
                  <Box className="mobile-filter-section">
                    <Box className="search-inventory">
                      <CustomInputWeb
                        type="text"
                        value={this.state.searchProducts}
                        label={this.t(`${configJSON.deactivateTextFeild}`)}
                        onChange={(event) => this.handleSearchProduct(event)}
                        placeholder={this.t(`${configJSON.searchPlaceholder}`)}
                        data-testId="searchextfeild"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        }
                      />
                    </Box>
                    <Button
                      className="filterImages"
                      onClick={(event) => this.sortByPrice(event)}
                      data-testId="sortBtn"
                    >
                      <img src={shortImg} alt="short img" />
                    </Button>
                    <Menu
                      anchorEl={this.state.isShortOption}
                      keepMounted
                      open={Boolean(this.state.isShortOption)}
                      onClose={this.closeSortModal}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      className={classes.dropdown}
                      data-testId="sortDropdown"
                    >
                      <MenuItem
                        onClick={this.closeSortModal}
                        data-testId="sortOption"
                      >
                        {this.t(`${configJSON.ShowAsOption1}`)}
                      </MenuItem>
                      <MenuItem>
                        {this.t(`${configJSON.ShowAsOption2}`)}
                      </MenuItem>
                      <MenuItem>
                        {this.t(`${configJSON.ShowAsOption3}`)}
                      </MenuItem>
                    </Menu>

                    <Box
                      onClick={this.handlefilterModal}
                      className="filterImages"
                    >
                      <img src={filterImg} alt="filter img" />
                    </Box>
                  </Box>
                </Box>
                {this.showCategoryTabs(classes)}
               
                <Box className="filters-section">
                  <Box className="filterBox">
                    <Typography className="filterTitle">
                      {this.t(`${configJSON.filtersTxt}`)}
                    </Typography>
                    <Typography
                      className="clearLink"
                      onClick={this.clearFilters}
                      data-testId="clearFilterLink"
                    >
                      {this.t(`${configJSON.clearFilterLink}`)}
                    </Typography>
                  </Box>
                  <Box className="filterBoxRight">
                    <Box className="productsCheckbox">
                      <Checkbox
                        checked={this.state.isProductChecked}
                        onChange={(event) => this.handleProductCheckbox(event)}
                        className={classes.checked}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        data-testId="productsCheckBox"
                      />
                      <Typography className="productTxt">
                      {displayText}
                      </Typography>
                      {this.state.checkedItems.length > 0 && (
                        <Box className="product-actions">
                          <Link
                            to="#"
                            className="action-link"
                            onClick={this.generateCataloguePDF}
                            data-test-id="downloadPDFId"
                          >
                            <img src={downloadGray} alt="downloadGray" />
                          </Link>
                          <Link
                            to="#"
                            className="action-link"
                            onClick={this.cloneItem}
                            data-test-id="cloneItemId"
                          >
                            <img src={copyGray} alt="copyGray" />
                          </Link>
                          <Link
                            to="#"
                            className="action-link"
                            data-test-id="deleteItemId"
                            onClick={this.deleteProduct}
                          >
                            <img src={deleteGray} alt="deleteGray" />
                          </Link>
                        </Box>
                      )}
                    </Box>
                    <Box className="productsBox">
                      <Box className="search-products">
                        <Input
                          value={this.state.searchCatalogue}
                          data-testId="txtInputSearch"
                          onChange={(e) =>
                            this.setState({ searchCatalogue: e.target.value })
                          }
                          placeholder={this.t(
                            `${configJSON.filterSearchPlaceholder}`
                          )}
                          type="search"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          disableUnderline
                        />
                      </Box>
                      <Box
                        className={classes.tabViewFilterBox}
                        onClick={this.handlefilterModal}
                        data-testId="filterBox"
                      >
                        <img
                          src={filterIcon}
                          alt="filter icon"
                          className="filter-img"
                        />
                        <Typography className="filterText">
                          {this.t(`${configJSON.filterText}`)}
                        </Typography>
                      </Box>
                      {this.state.currentCategory ==
                        configJSON.ProductsStaticId.toString() && (
                        <FormControl className={classes.selectFormControl}>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: { paper: classes.dropdownStyle },
                            }}
                            displayEmpty
                            defaultValue={this.t(`${configJSON.ShowasTxt}`)}
                            data-test-id="showProductAs"
                            disableUnderline
                            value={this.state.showPriceAs}
                            onChange={this.changePrice}
                            renderValue={(value: any) => {
                              let valueString: string = value;
                              if (value == "") {
                                valueString = configJSON.ShowasTxt;
                              }
                              return <Box>{valueString}</Box>;
                            }}
                          >
                            {this.state.priceLabelOptions.map((price: any) => {
                              return (
                                <MenuItem key={price.id} value={price.title}>
                                  {price.title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                      <FormControl className={classes.selectFormControl}>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle },
                          }}
                          displayEmpty
                          defaultValue={this.t(`${configJSON.SortTxt}`)}
                          data-testid="sortProducts"
                          disableUnderline
                          renderValue={(value: any) => {
                            return <Box>{value}</Box>;
                          }}
                          onChange={this.changeSortBy}
                        >
                          <MenuItem value={configJSON.SortOption1}>
                            {this.t(`${configJSON.SortOption1}`)}
                          </MenuItem>
                          {this.state.currentCategory ==
                            configJSON.ProductsStaticId.toString() && (
                            <MenuItem value={configJSON.SortOption2}>
                              {this.t(`${configJSON.SortOption2}`)}
                            </MenuItem>
                          )}
                          {this.state.currentCategory ==
                            configJSON.ProductsStaticId.toString() && (
                            <MenuItem value={configJSON.SortOption3}>
                              {this.t(`${configJSON.SortOption3}`)}
                            </MenuItem>
                          )}
                          <MenuItem value={configJSON.SortOption4}>
                            {this.t(`${configJSON.SortOption4}`)}
                          </MenuItem>
                          <MenuItem value={configJSON.SortOption5}>
                            {this.t(`${configJSON.SortOption5}`)}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                <Grid
                  container
                  className={classes.inventoryContent}
                  ref={this.targetRef}
                >
                  <Grid
                    item
                    className="filtersGrid"
                  >
                    <FormControl
                      component="fieldset"
                      className={`${classes.radioChoose} `}
                    >
                      <RadioGroup
                        className="radio-group"
                        name="role"
                        value={this.state.currentCategory}
                        onChange={this.changeCategory}
                        defaultValue={this.state.currentCategory}
                        data-test-id="categoryRadio"
                      >
                        <FormControlLabel
                          value={configJSON.ProductsStaticId.toString()}
                          control={<Radio />}
                          label={this.t(`${configJSON.catergoryName1}`)}
                        />
                        <FormControlLabel
                          value={configJSON.ToolsStaticId.toString()}
                          control={<Radio />}
                          label={this.t(`${configJSON.catergoryName2}`)}
                        />
                        <FormControlLabel
                          value={configJSON.AssetsStaticId.toString()}
                          control={<Radio />}
                          label={this.t(`${configJSON.catergoryName3}`)}
                        />
                        <FormControlLabel
                          value={configJSON.ConsumablesStaticId.toString()}
                          control={<Radio />}
                          label={this.t(`${configJSON.catergoryName4}`)}
                        />
                      </RadioGroup>
                    </FormControl>
                    {getFilterAccordion()}
                  </Grid>
                  
                    {this.filterItem().length === 0 ? (
                      this.noItemsDiv(classes)
                    ) : (
                      <Grid item className="filtersGridContent">
                      <Box
                        className={`${classes.viewItemContainer} inventory-checkbox-wrapper`}
                      >
                        {this.filterItem().map((item: any) => {
                          return (
                            <Box className="view-item-col" key={item.id}>
                              <InventoryItem
                                key={item.id}
                                isItemChecked={this.state.checkedItems.includes(
                                  item.id
                                )}
                                item={item}
                                handleItemCheckbox={this.handleItemCheckbox}
                                viewNavigate={this.viewNavigate}
                                showPriceAs={this.state.showPriceAs}
                                data-testId={`inventory-item-${item.id}`}
                              />
                            </Box>
                          );
                        })}
                        <Button
                          className={classes.viewItemBtn}
                          onClick={(event) => this.addInventoryOption(event)}
                          data-testId="addInventoryBtn"
                        >
                          + {this.t(`${configJSON.addBtn}`)}
                        </Button>
                      </Box>
                      </Grid>
                    )}
                  
                </Grid>
              </Box>
              <Modal
                open={this.state.isModalOpen}
                onClose={this.closefilterModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalDialog}
                data-testId="filterModal"
                BackdropProps={{ className: "backdrop-root" }}
              >
                <Box className={classes.modalDialogBox}>
                  <Box className="modal-heading">
                    <Typography className="modal-title">
                      {this.t(`${configJSON.filterText}`)}
                    </Typography>
                    <Box className="heading-right">
                      <Typography
                        className="clearLink"
                        onClick={this.clearFilters}
                      >
                        {this.t(`${configJSON.clearFilterLink}`)}
                      </Typography>
                      <CloseIcon
                        className="close-icon"
                        onClick={this.closefilterModal}
                        data-testId="filterCloseIcon"
                      />
                    </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box className="categoryfilters">
                      {this.showCategoryTabs(classes)}
                      {getFilterAccordion()}
                    </Box>
                  </Box>
                </Box>
              </Modal>
              <Menu
                data-testId="addInventoryOtptionMenu"
                anchorEl={this.state.isOptionMenu}
                keepMounted
                open={Boolean(this.state.isOptionMenu)}
                onClose={this.optionModal}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className={classes.dropdown}
                getContentAnchorEl={null}
              >
                <MenuItem
                  onClick={() => this.toAddinvetory()}
                  data-testId="menuOption"
                >
                  {this.t(`${configJSON.addManuallyTxt}`)}
                </MenuItem>
                <MenuItem
                  onClick={this.navigateToBulk}
                  data-testId="navigateToBulk"
                >
                  {this.t(`${configJSON.bulkUploadTxt}`)}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {this.showMoreModal(classes)}
          {this.chooseContactModal(classes)}
          {this.activityFilterModal(classes)}
          {this.favouriteProductModal(classes)}
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(
  InventoryManagement
);
// Customizable Area End
