import React from "react";
// Customizable Area Start
import ViewDealsControllerWeb, {
  Props,
  configJSON,
} from "./ViewDealsControllerWeb.web";
// Customizable Area End

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Tabs,
  Tab,
  Modal,
  ListItemIcon,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  ListItemText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import {
  editGray,
  contentCopy,
  deleteOutline,
  fileTree,
  calendarGray,
  attachmentsDeals,
  download,
  plusCircle,
  editCircle,
  checkCircle,
  arrowULeft,
  imgCreateTaskFilter,
  downloadGray,
} from "./assets";
import { customStyles } from "../../../components/src/LeadManagmentHelper.web";
import WebLoader from "../../../components/src/WebLoader.web";
import {
  getImageForSubCategory,
  extractItemDetails,
} from "../../../components/src/ReusableFunctions";
import moment from "moment";
import StarIcon from "@material-ui/icons/Star";
import ProductItemsModal from "../../../components/src/ProductItemsModal.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import ActivityLog from "../../ActivityLog/src/ActivityLog.web";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CustomInputWeb from "../../../components/src/CustomInput.web";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function ViewDealTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
export function viwA11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Customizable Area End

export class ViewDeals extends ViewDealsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showProductsTab = (productsLength:number, dealProducts: any) => {
    const {classes} = this.props;
    return (
    <ViewDealTabPanel value={this.state.viewTabsValue} index={1}>
                <Box className="description-box">
                  <Box className="content-heading">
                    <Box className="title">
                      {productsLength}{" "}
                      {productsLength > 1 ? "Products" : "Product"}
                    </Box>
                    <Box className="content-filter">
                      <FormControl className="select-control sortby-control border-hr">
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              horizontal: "left",
                              vertical: "top",
                            },
                            classes: { paper: classes.dropdownStyle },
                            getContentAnchorEl: null,
                          }}
                          defaultValue="Sort by"
                          displayEmpty
                          data-test-id="handleSortChange"
                          renderValue={(value: any) => {
                            return <Box>{value}</Box>;
                          }}
                          IconComponent={() => (
                            <ExpandMoreIcon className="down-arrow" />
                          )}
                        >
                          <MenuItem value="A-Z">A-Z</MenuItem>
                          <MenuItem value="Z-A">Z-A</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="product-list-row">
                    {dealProducts !== undefined && dealProducts !== null ? (
                      <>
                        {dealProducts.data.map((item: any) => {
                          return (
                            <ProductItemsModal
                              item={item}
                            />
                          );
                        })}
                      </>
                    ) : (
                      "No produts found"
                    )}
                  </Box>
                </Box>
              </ViewDealTabPanel>
    );
  }
  showAttachmentsTab = (dealAttachments: any) => {
    return (
      <ViewDealTabPanel value={this.state.viewTabsValue} index={2}>
      <Box className="description-box">
        <List
          className="attachments-list"
        >
          {dealAttachments !== undefined &&
          dealAttachments !== null ? (
            <>
              {dealAttachments.map((attachment: any) => {
                const isImage = (filename: string) => {
                  const imageExtensions = ['jpg', 'jpeg', 'png', 'svg'];
                  const fileExtension = filename.split('.').pop()?.toLowerCase();
                  return imageExtensions.includes(fileExtension || '');
                };
                return (
                  <ListItem
                    className="list-item"
                    key={attachment.id}
                  >
                    <a
                      href={attachment.url}
                      target="_blank"
                      className="list-item-inner"
                    >
                      <Box className="list-img">
                        <img
                          src={isImage(attachment.filename) ? attachment.url : downloadGray}
                          alt={isImage(attachment.filename) ? attachment.filename : "download"}
                        />
                      </Box>
                      <Box className="list-name">
                        {attachment.filename}
                      </Box>
                    </a>
                  </ListItem>
                );
              })}
            </>
          ) : (
            "No attachments found"
          )}
        </List>
      </Box>
    </ViewDealTabPanel>
    )
  }
  showDeleteIcon = () => {
    const {deleteDealHandler} = this.props;
    return (
      <>
      {this.userRole !== "employee" && 
                <Box className="heading-link">
                  <Link
                    to="#"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => deleteDealHandler(this.props.dealId)}
                    //onClick={(e) => this.handleMenuOpen(e)}
                    className="dropmenu-btn"
                    data-test-id="deleteDeal"
                  >
                    <img src={deleteOutline} alt="deleteOutline" />
                  </Link>
                </Box>
                }
      </>
    )
  }
  renderStatusItems = () => {
    const statusItems = [
      { value: 0, name: "Initiated", className: "sky-blue" },
      { value: 1, name: "Sent Proposal", className: "orange" },
      { value: 2, name: "Follow Up", className: "yellow" },
      { value: 3, name: "Won", className: "green" },
      { value: 4, name: "Lost", className: "black" },
    ];

    return statusItems.map((item) => (
      <MenuItem key={item.value} value={item.value} className="status-item">
        <Box className="status-name">{item.name}</Box>
        <Box className={`status ${item.className}`}></Box>
      </MenuItem>
    ));
  };
  showChangeStageModal = (currentValue:number) => {
    const {classes} = this.props;
    if(this.state.isChangingStage){
      return (
        <Modal
        open={this.state.isChangingStage}
        onClose={this.closeStageChangeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${classes.modalDialog}`}
        BackdropProps={{ className: "backdrop-root" }}
        data-test-id="activeStageModal"
      >
        <Box
          className={`${classes.modalCenterDialogBox} modalCenterDialogBox`}
        >
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              {this.t(`${configJSON.changeStageTxt}`)}
            </Typography>
            <Link
              to="#"
              className="close-icon"
              data-test-id="stage-modal-close"
              onClick={this.closeStageChangeModal}
            >
              <CloseOutlinedIcon />
            </Link>
          </Box>
          <Box className="modal-description-stage">
            <Box className="modal-form-stage" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <FormControl
                      className="select-outer"
                      variant="outlined"
                    >
                      <Select
                        labelId="current-stage"
                        id="current-stage"
                        value={this.state.existingDealStage ?? currentValue }
                        defaultValue={
                          currentValue
                        }
                        onChange={this.handleStageEdit}
                        name="newStage"
                        label="Current Stage"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyleDeals} modal-dropdown`,
                          },
                        }}
                        data-test-id="modalChangeStatus"
                        IconComponent={ExpandMoreIcon}
                      >
                        {this.renderStatusItems()}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label="Add a note"
                      value={this.state.stageChangeNote}
                      name="addNote"
                      onChange={(event) =>
                        this.handleStageNote(event.target.value)
                      }
                      data-test-id="dealNote-input"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="modal-footer">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title="Cancel"
                onClick={this.closeStageChangeModal}
              >
                {this.t(`${configJSON.cancelTxt}`)}
              </Button>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Update"
                onClick={this.updateDealStage}
                data-test-id="updateDealStage"
              >
                {this.t(`${configJSON.updateTxt}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      )
    }
    
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    let dealName,
      dealCloseDate,
      dealStage,
      dealDescription,
      dealOwner,
      dealContact,
      dealAmount,
      dealAttachments,
      dealTasks,
      hasProducts,
      dealProducts,
      hasAttachments,
      attachmentsLength,
      productsLength,
      stageClass;
    const {
      classes,
      openViewModal,
      closeViewModal,
      duplicateHandler,
      deleteDealHandler,
      editDealHAndler,
    } = this.props;
    const { singleDealDetail } = this.state;
    

    if (singleDealDetail && singleDealDetail.attributes) {
      dealName = singleDealDetail.attributes.name;
      dealCloseDate = singleDealDetail.attributes.close_date;
      dealStage = singleDealDetail.attributes.stage;
      dealDescription = singleDealDetail.attributes.description;
      dealOwner = singleDealDetail.attributes.deal_owner.name;
      dealContact = singleDealDetail.attributes.contacts;
      dealAmount = singleDealDetail.attributes.total_amount;
      hasAttachments = singleDealDetail.attributes.has_attachments;
      hasProducts = singleDealDetail.attributes.has_products;
      dealAttachments = singleDealDetail.attributes.attachments;
      dealProducts = singleDealDetail.attributes.products;
      dealTasks = singleDealDetail.attributes.tasks;
    }

    if (hasProducts !== null) {
      productsLength = singleDealDetail?.attributes?.products?.data.length;
    }

    if (hasAttachments !== null) {
      attachmentsLength = singleDealDetail?.attributes?.attachments.length;
    } else {
      attachmentsLength = "0";
    }

    if (dealStage === "Initiated") {
      stageClass = "sky-blue";
    } else if (dealStage === "sent proposal") {
      stageClass = "orange";
    } else if (dealStage === "follow up") {
      stageClass = "yellow";
    } else if (dealStage === "won") {
      stageClass = "green";
    } else if (dealStage === "lost") {
      stageClass = "black";
    }

    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <GlobalHeaderWeb t={this.t} />
        <Modal
          className={classes.modalDialog}
          aria-labelledby="modal-modal-title"
          onClose={closeViewModal}
          open={openViewModal}
          aria-describedby="modal-modal-description"
        >
          <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
            <Box className="modal-heading">
              <Box className="heading-links">
                <Box className="heading-link">
                  <Link
                    data-test-id="viewEditProject"
                    onClick={(event: any) =>
                      editDealHAndler(event, this.props.dealId)
                    }
                    to="#"
                    className="icon"
                  >
                    <img src={editGray} alt="editGray" />
                  </Link>
                </Box>
                <Box className="heading-link">
                  <Link
                    to="#"
                    className="icon"
                    onClick={() => duplicateHandler(this.props.dealId)}
                    data-test-id="dealDuplicate"
                  >
                    <img src={contentCopy} alt="contentCopy" />
                  </Link>
                </Box>
                <Box className="heading-link">
                  <Link to="#" className="icon" data-testid="duplicateTask">
                    <img src={fileTree} alt="fileTree" />
                  </Link>
                </Box>
                {this.showDeleteIcon()}
              </Box>
              <Link
                to="#"
                className="close-icon"
                onClick={closeViewModal}
                data-testid="viewTaskClose"
              >
                <CloseIcon />
              </Link>
            </Box>
            <Box className="modal-description-deal">
              <Typography className="modal-title" component="h2">
                {dealName}
              </Typography>
              <List className="dealview-list">
                <ListItem>
                  <Box className="list-label">
                    {this.t(`${configJSON.stageTxt}`)}
                  </Box>
                  <Box className="list-description">
                    <FormControl
                      className={`select-control-deals status-control ${stageClass} on-track`}
                    >
                      <Select
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyle} modal-dropdown`,
                          },
                        }}
                        displayEmpty
                        value={dealStage !== undefined ? dealStage : ""}
                        defaultValue={dealStage !== undefined ? dealStage : ""}
                        disabled={true}
                        data-test-id="dealStageHandler"
                        onClick={this.openStageChangeModal}
                      >
                        <MenuItem value={dealStage}>{dealStage}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box className="list-label">
                    {this.t(`${configJSON.amountTxt}`)}
                  </Box>

                  {dealAmount == null ? (
                    <Box className="list-description">-</Box>
                  ) : (
                    <Box className="list-description">$ {Number(dealAmount).toFixed(0)}</Box>
                  )}
                </ListItem>
                <ListItem>
                  <Box className="list-label">
                    {this.t(`${configJSON.dealOwnerTxt}`)}
                  </Box>
                  <Box className="list-description">{dealOwner}</Box>
                </ListItem>
                <ListItem>
                  <Box className="list-label">
                    {this.t(`${configJSON.contactTxtNew}`)}
                  </Box>
                  {dealContact && (
                    <>
                      {dealContact.length > 0 && (
                        <Box className="list-description">
                          {dealContact
                            .map(
                              (contact: { id: string; name: string }) =>
                                contact.name
                            )
                            .join(", ")}
                        </Box>
                      )}
                    </>
                  )}
                </ListItem>
                <ListItem className="date-item-root">
                  <Box className="list-label">
                    {this.t(`${configJSON.expectedDateCloseTxt}`)}
                  </Box>
                  <Box className="list-description">
                    {dealCloseDate == null ? (
                      "-"
                    ) : (
                      <Box className="date-cal-icon">
                        {moment(dealCloseDate).format("MMM DD, YYYY")}
                        <img
                          className="icon"
                          src={calendarGray}
                          alt="calendarGray"
                        />
                      </Box>
                    )}
                  </Box>
                </ListItem>
              </List>
              <Box className="tasklist-tabwrapper">
                <Box>
                  <Tabs
                    aria-label="simple tabs example"
                    value={this.state.viewTabsValue}
                    onChange={this.setViewTabsValue}
                    data-test-id="viewTaskBottomTabs"
                  >
                    <Tab
                      label={this.t(`${configJSON.descriptionTxt}`)}
                      {...viwA11yProps(0)}
                    />
                    <Tab
                      label={this.t(configJSON.associatedProductTxt, {
                        count: 3,
                      })}
                      {...viwA11yProps(1)}
                    />
                    <Tab
                      label={this.t(configJSON.attacmentsTxt, {
                        count: `${attachmentsLength}`,
                      })}
                      {...viwA11yProps(2)}
                    />
                    <Tab
                      label={this.t(`${configJSON.activityLogsTxt}`)}
                      {...viwA11yProps(3)}
                    />
                  </Tabs>
                </Box>
                <ViewDealTabPanel
                  value={this.state.viewTabsValue}
                  index={0}
                  data-test-id="description-tab"
                >
                  <Box className="description-box">
                    <Typography className="overview-txt">
                      {dealDescription}
                    </Typography>
                  </Box>
                </ViewDealTabPanel>
                {this.showProductsTab(productsLength, dealProducts)}
                {this.showAttachmentsTab(dealAttachments)}
               
                <ViewDealTabPanel value={this.state.viewTabsValue} index={3}>
                  <ActivityLog
                    classes={classes}
                    activityType="deal"
                    activityId={this.props.dealId}
                    activityTitle={dealName}
                  />
                </ViewDealTabPanel>
              </Box>
              <Box className="tasklist-tabwrapper">
                <Box>
                  <Tabs
                    aria-label="simple tabs example"
                    value={this.state.ProjectTaskTabsValue}
                    onChange={this.setProjectTaskTabsValue}
                    data-test-id="projectTaskBottomTabs"
                  >
                    <Tab
                      label={this.t(`${configJSON.taskListsTxt}`)}
                      {...viwA11yProps(0)}
                    />
                    <Tab
                      label={this.t(`${configJSON.tasksTxt}`)}
                      {...viwA11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <ViewDealTabPanel
                  value={this.state.ProjectTaskTabsValue}
                  index={0}
                >
                  {dealTasks !== undefined &&
          dealTasks !== null ? (
            <>
            {dealTasks.map((tasklist:any) => {
              return (
                <Box className="deals-task-wrap" key={tasklist.id}>
                  <Box className="deal-task-heading">
                    <Box className="deal-heading">
                      {tasklist.title}
                    </Box>
                    <Box className="deal-total-task">
                      3 {this.t(`${configJSON.tasksTxt}`)}
                    </Box>
                  </Box>

                  <List className="deal-task-ul">
                    {[0, 1].map((value) => {
                      return (
                        <ListItem
                          //onClick={this.handleTaskListTasks(value)}
                          dense
                          role={undefined}
                          key={value}
                          className="deal-task-li"
                        >
                          <Box className="deal-inner">
                            <ListItemIcon className="tasklist-icon">
                            </ListItemIcon>
                            <Box className="tasklist-label">
                              <Box className="task-title">
                                <Box className="title">
                                  Task {value}
                                </Box>
                                <Box className="title-right">
                                  <Box className="controller-link">
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="task-desc">
                                <Box className="desc-left">
                                  <Box
                                    className="date"
                                    component="span"
                                  >
                                    Aug 07, 2019
                                  </Box>
                                </Box>
                                <Box className="desc-right">
                                  <Box
                                    className="timing"
                                    component="span"
                                  >
                                    38:52 / 50:00
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              );

            })}
            </>
            

          ) : (

            <Box className="no-task-block">
            {/* <img
            src={formatTreeBlue}
            alt="formatTreeBlue"
            className="task-img"
          /> */}
            <Box className="heading">
              {this.t(`${configJSON.noTaskListTxt}`)}
            </Box>
            <Box className="sub-txt">
              {this.t(`${configJSON.noTaskSubTxt}`)}
            </Box>
            <Box className="button-wrapper full-btn">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title="+ Create Tasklists"
                //onClick={this.tasklistPopoverOpen}
              >
                + {this.t(`${configJSON.craeteTasklistTxt}`)}
              </Button>
            </Box>
          </Box>
          )}
                  
                </ViewDealTabPanel>
              </Box>
            </Box>
          </Box>
        </Modal>
        
          
         {this.showChangeStageModal(this.state.existingDealStage)}
        
        
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(ViewDeals);
// Customizable Area End
