import React from "react";

import {
  Box,
  Typography,
  Grid,
// Customizable Area Start
Divider,
Stepper,
Step,
StepLabel,
Button,
Drawer,
MenuItem,
FormControl,
Select,
InputLabel,
InputAdornment,
FormHelperText
// Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import {
    withStyles,
} from "@material-ui/core/styles";
import {
    beerImg,
    tractorImg,
    vesselImg,
    landImg,
    productsImg,
    toolsImg,
    wineImg,
    nailImg,
    assetsImg,
    comsumable,
    fuelImg,
    fuelIcon,
    testtubeImg,
    otherProducts,
    spiritImg
} from "../../categoriessubcategories/src/assets";

import { StepIconProps } from "@material-ui/core/StepIcon";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { detailsIcon,tickIcon,category,notes,allergens} from "../../catalogue/src/assets";
import { attachment} from "./assets";
import Allergens from "./Allergens.web";
import ItemDetails from "./ItemDetails.web";
import TastingNotes from "./TastingNotes.web";
import ItemBasicInfo from "./ItemBasicInfo.web";
import ItemMoreDetails from "./ItemMoreDetails.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import CustomInput from "../../../components/src/CustomInput.web";
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import WebLoader from "../../../components/src/WebLoader.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";
import Location from "./Location.web";
import ToolsInfo from "./ToolsInfo.web";
import ToolsAdditionalInfoWeb from "./ToolsAdditionalInfo.web";
import GoogleMapComponent  from "../../../components/src/MapEmbed.web";

const icons: { [index: string]: React.ReactElement } = {
    1: <LockOutlinedIcon />,
    2: <LockOutlinedIcon />,
    3: <LockOutlinedIcon />,
    4: <LockOutlinedIcon />,
    5: <LockOutlinedIcon />,
    6: <LockOutlinedIcon />,
    7: <LockOutlinedIcon />,
  };
// Customizable Area End

import AddItemController, {
    Props,
    configJSON,
  } from "./AddItemController.web";
  
export class AddItem extends AddItemController {
constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start 
ColorlibStepIcon=(props: StepIconProps)=> {
    const { active, completed, icon } = props;
    let stepIconClassName = "stepIcon";
    if (completed) {
        stepIconClassName += " completed";
    } else if (active) {
        stepIconClassName += " active";
    }
        
    const setIcons = () => {
      switch (this.state.chosenItem){
        case "Wine": 
        case "Beer":
        case "Other Product":
        default:
          switch (icon) {
          case 1: 
            return(<img src={category} />)
          case 2:
            return(<img src={detailsIcon} />)
          case 3:
            return(<img src={detailsIcon} />)
          case 4:
            return(<img src={detailsIcon} />)
          case 5:
            return(<img src={notes} />)
          case 6:
            return(<img src={allergens} />)
          case 7:
              // return(<img src={detailsIcon} />)  
          default:
              return icons[String(icon)];
          }
      }        
    };
    return (
      <Box
        className={`stepIcon ${completed ? "completed" : active ? "active" : ""}`}
      >
        {completed ? (
          <CheckIcon className="completed" />
        ): active ? (
            <>{setIcons()}</>
          ) : (
            <>{icons[String(props.icon)]}</>
          )}
      </Box>
    );
}

getMainStepContent=()=>{
  const {classes}=this.props
  return(
    <Box className={classes.categorySection}>
        <Box className="headingSection">
            <Typography className={classes.heading} variant="h1">
                {this.t(`${configJSON.addInventoryTitle}`)}
            </Typography>
            <Typography className={classes.subtitleText}>
            {this.t(`${configJSON.addInventorySubTitle}`)}
            </Typography>
        </Box>
        <Box className={classes.categoryCardSection}>
            <Box className={classes.infoSection}>
                {this.state.categorySwitch.Product && <Box className="info-list">
                    <Box className="categoriesInfo">
                        <Box className="categoryBox">
                            <img
                            src={productsImg}
                            alt="product img"
                            className="categoryImg"
                            />
                            <Typography className="categoryText">
                            {this.t(`${configJSON.catergoryName1}`)}
                            </Typography>
                        </Box>
                        <Typography className={classes.descriptionText}>
                        {this.t(`${configJSON.productDescription}`)}
                        </Typography>
                      {this.state.subCategorySwitch.Wine &&
                        <Box className="subCategoryBox" 
                        data-testId={`itemBox${configJSON.wineText}`}
                        onClick={()=>this.chooseSubcategory(configJSON.wineText)}
                        >
                            <div className="product-img">
                            <img
                            src={wineImg}
                            alt="product img"
                            className="subCategoryImg"
                            />
                            </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.wineText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>                        
                       }
                       {this.state.subCategorySwitch.Beer &&
                        <Box className="subCategoryBox" data-testId={`itemBox${configJSON.beerText}`}
                        onClick={()=>this.chooseSubcategory(configJSON.beerText)}
                        >
                          <div className="product-img">
                            <img
                            src={beerImg}
                            alt="product img"
                            className="subCategoryImg"
                            />
                          </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.beerText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>
                      }
                        {this.state.subCategorySwitch.Spirit &&
                        <Box className="subCategoryBox" 
                        data-testId={`itemBox${configJSON.spiritText}`}
                        onClick={()=>this.chooseSubcategory(configJSON.spiritText)}
                        >
                          <div className="product-img">
                            <img
                            alt="product img"
                            src={spiritImg}
                            className="subCategoryImg"
                            />
                          </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.spiritText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>
                        }
                        {this.state.subCategorySwitch.OtherProduct && <Box className="subCategoryBox" data-testId={"itemBoxOther"}
                        onClick={()=>this.chooseSubcategory(configJSON.otherText)}
                        >
                          <div className="product-img">
                            <img
                            src={otherProducts}
                            alt="product img"
                            className="subCategoryImg"
                            />
                          </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.otherText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>}
                    </Box>
                </Box>}
                {this.state.categorySwitch.Tool && <Box className="info-list">
                    <Box className="categoriesInfo" style={{maxHeight:"95%"}}>
                        <Box className="categoryBox">
                            <img src={toolsImg} alt="product img" className="categoryImg" />
                            <Typography className="categoryText">
                            {this.t(`${configJSON.catergoryName2}`)}
                            </Typography>
                        </Box>
                        <Typography className={classes.descriptionText}>
                        {this.t(`${configJSON.toolsdescription}`)}
                        </Typography>
                       { this.state.subCategorySwitch.Machinery &&
                        <Box className="subCategoryBox" data-testId={`itemBox${configJSON.machineText}`}
                        onClick={()=>this.chooseSubcategory(configJSON.machineText)}
                        >
                          <div className="product-img">
                            <img
                            src={nailImg}
                            alt="product img"
                            className="subCategoryImg"
                            />
                          </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.machineText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>
                        }
                       { this.state.subCategorySwitch.Hardware &&<Box className="subCategoryBox" data-testId={`itemBox${configJSON.hardwareText}`}
                        onClick={()=>this.chooseSubcategory(configJSON.hardwareText)}
                        >
                          <div className="product-img">
                            <img
                            src={tractorImg}
                            alt="product img"
                            className="subCategoryImg"
                            />
                          </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.hardwareText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>}
                    </Box>
                </Box>}
                
                {this.state.categorySwitch.Asset && <Box className="info-list">
                    <Box className="categoriesInfo">
                        <Box className="categoryBox">
                            <img
                            src={assetsImg}
                            alt="product img"
                            className="categoryImg"
                            />
                            <Typography className="categoryText">
                            {this.t(`${configJSON.catergoryName3}`)}
                            </Typography>
                        </Box>
                        <Typography className={classes.descriptionText}>
                        {this.t(`${configJSON.assetsDescription}`)}
                        </Typography>
                        {this.state.subCategorySwitch.Land &&
                          <Box className="subCategoryBox"
                        onClick={()=>this.chooseSubcategory(configJSON.landText)}
                        data-test-id={"landTxt"}
                        >
                          <div className="product-img">
                            <img
                              src={landImg}
                              alt="product img"
                              className="subCategoryImg"
                            />
                          </div>                            
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.landText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                          </Box>
                        }
                        {this.state.subCategorySwitch.Vessel &&<Box className="subCategoryBox"
                        onClick={()=>this.chooseSubcategory(configJSON.vesselsText)}
                        data-test-id={"vesselTxt"}
                        >
                          <div className="product-img">
                            <img
                            src={vesselImg}
                            alt="product img"
                            className="subCategoryImg"
                            />
                          </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.vesselsText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>}
                    </Box>
                </Box>}
                {this.state.categorySwitch.Consumable && <Box className="info-list">
                    <Box className="categoriesInfo" style={{marginTop:"-140px"}}>
                        <Box className="categoryBox">
                            <img
                            src={comsumable}
                            alt="product img"
                            className="comsumableImg"
                            />
                            <Typography className="categoryText">
                            {this.t(`${configJSON.catergoryName4}`)}
                            </Typography>
                        </Box>
                        <Typography className={classes.descriptionText}>
                        {this.t(`${configJSON.consumableDescription}`)}
                        </Typography>
                       { this.state.subCategorySwitch.Fertilizer && 
                        <Box className="subCategoryBox"
                        onClick={()=>this.chooseSubcategory(configJSON.FertilizersText)}
                        data-test-id={"fertilizerTxt"}
                        >
                           <div className="product-img">
                              <img
                              src={fuelIcon}
                              alt="product img"
                              className="subCategoryImg"
                              />
                            </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.FertilizersText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>
                        }
                        {this.state.subCategorySwitch.PhytoProduct && 
                         <Box className="subCategoryBox"
                        data-test-id={"phytoProductTxt"}
                        onClick={()=>this.chooseSubcategory(configJSON.PhytoProductsText)}
                        >
                            <div className="product-img">
                              <img
                              src={fuelImg}
                              alt="product img"
                              className="subCategoryImg"
                              />
                            </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.PhytoProductsText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon} />
                         </Box>
                        }
                        {this.state.subCategorySwitch.Chemical && <Box className="subCategoryBox-chemical"
                        data-test-id={"chemicalTxt"}
                        onClick={()=>this.chooseSubcategory(configJSON.ChemicalsText)}
                        >
                            <div className="product-img">
                              <img
                              src={testtubeImg}
                              alt="product img"
                              className="subCategoryImg"
                            />
                            </div>
                            <Typography className="subCategoryText">
                            {this.t(`${configJSON.ChemicalsText}`)}
                            </Typography>
                            <ChevronRightIcon className={classes.arrowIcon}/>
                        </Box>}
                    </Box>
                </Box>}            
            </Box>          
        </Box>
    </Box> 
  )
}

getStepContent = (step: number) => {
      switch (step) {
        case 0:
          return (
            this.getMainStepContent()
          );
        case 1:
          return (
            <ItemBasicInfo 
              seletedItem={this.state.chosenItem}   
              stepNumber={this.state.addingsteps} backBtn={this.backStep}
              nextBtn={this.nextStep}
            />
          );
        case 2:
          return (
            <Location 
              seletedItem={this.state.chosenItem}   
              stepNumber={this.state.addingsteps} 
              backBtn={this.backStep}
              nextBtn={this.nextStep}
            />
          );
        case 3: 
          return <ItemDetails 
              seletedItem={this.state.chosenItem}   
              stepNumber={this.state.addingsteps} backBtn={this.backStep}
              nextBtn={this.nextStep}
            />
        case 4: 
        return <TastingNotes seletedItem={this.state.chosenItem}   
          stepNumber={this.state.addingsteps} backBtn={this.backStep}
          nextBtn={this.nextStep}/>
        case 5: 
        return <Allergens seletedItem={this.state.chosenItem}   
            stepNumber={this.state.addingsteps} backBtn={this.backStep}
            goToSuccess={this.itemAddedSuccess} data-testId={'wineAllergens'}
          />
        default:
          return <>{this.t(`${configJSON.deafultStep}`)}</>;
      }     
};

getBeerStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (this.getMainStepContent());
      case 1:
        return (<ItemBasicInfo seletedItem={this.state.chosenItem}   
            stepNumber={this.state.addingsteps} backBtn={this.backStep}
            nextBtn={this.nextStep}
          />
        );
      case 2:
        return (<Location 
            seletedItem={this.state.chosenItem}   
            stepNumber={this.state.addingsteps} 
            backBtn={this.backStep}
            nextBtn={this.nextStep}
          />
        );
      case 3: 
        return <ItemDetails 
            seletedItem={this.state.chosenItem}   
            stepNumber={this.state.addingsteps} 
            backBtn={this.backStep}
            nextBtn={this.nextStep}
        />
      case 4: 
      return <ItemMoreDetails seletedItem={this.state.chosenItem}   
        stepNumber={this.state.addingsteps} backBtn={this.backStep}
        nextBtn={this.nextStep}
        />
      case 5: 
        return <TastingNotes seletedItem={this.state.chosenItem}   
        stepNumber={this.state.addingsteps} backBtn={this.backStep}
        nextBtn={this.nextStep}
        />
      case 6: 
        return <Allergens seletedItem={this.state.chosenItem} stepNumber={this.state.addingsteps}
            goToSuccess={this.itemAddedSuccess} backBtn={this.backStep}
          />
      default:
        return <>{this.t(`${configJSON.deafultStep}`)}</>;
      } 
};


getOtherStepContent = (step: number) => {
  switch (step) {
    case 0:
      return (this.getMainStepContent());
    case 1:
      return (
        <ItemBasicInfo 
          seletedItem={this.state.chosenItem} nextBtn={this.nextStep}
          stepNumber={this.state.addingsteps} backBtn={this.backStep}
        />
      );
    case 2:
      return (
        <Location stepNumber={this.state.addingsteps} 
          seletedItem={this.state.chosenItem}   
          backBtn={this.backStep}
          nextBtn={this.nextStep}
        />
      );
    case 3: 
      return <ItemDetails seletedItem={this.state.chosenItem}   
          stepNumber={this.state.addingsteps} backBtn={this.backStep}
          nextBtn={this.nextStep}
        />
    case 4: 
      return <Allergens seletedItem={this.state.chosenItem} backBtn={this.backStep}
      stepNumber={this.state.addingsteps} goToSuccess={this.itemAddedSuccess}
    />
    default:
      return <>{this.t(`${configJSON.deafultStep}`)}</>;
  }     
};

getAttachmentContent = () => {
  const { classes } = this.props;
  return (
    <>
      <div data-test-id="attachments" className={classes.attachment}>
       {this.t(`${configJSON.attachmentText}`)}
        <Box className={classes.iconsBox} data-test-id="icons-box">
          <input hidden ref={this.inputRef} multiple type="file" onChange={this.fileUpload} data-test-id="file-input" />
          <img src={attachment} onClick={this.handleEditClick} data-test-id="file-icon" alt="image" />
          <p>{this.t(`${configJSON.uploadText1}`)}&nbsp;<a onClick={this.handleEditClick} title="browse">{this.t(`${configJSON.browseText}`)}</a>&nbsp;{this.t(`${configJSON.uploadText2}`)}&nbsp;{this.t(`${configJSON.uploadImgIext}`)}</p>
      </Box>
      </div>

      {this.state.addedFiles.map((img: any, index: number) => (
        <Box key={index} className={classes.uploadFileBox} data-test-id={`file-preview-${index}`}>
          <img src={tickIcon} data-test-id={`tick-icon-${index}`} />
          <Typography className="image-text" data-test-id={`file-name-${index}`}>
            {img.filename}
          </Typography>
          <CloseIcon onClick={()=>this.removeAddedFile(img.id)} className="file-close-icon" data-test-id={`remove-icon-${index}`} />
        </Box>
      ))}

      {this.state.fileview.map((img: any, index: number) => (
        <Box key={index} className={classes.uploadFileBox} data-test-id={`file-preview-${index}`}>
          <img src={tickIcon} data-test-id={`tick-icon-${index}`} />
          <Typography className="image-text" data-test-id={`file-name-${index}`}>
            {img.name}
          </Typography>
          <CloseIcon onClick={()=>this.removeFile(index)} className="file-close-icon" data-test-id={`close-icon-${index}`} />
        </Box>
      ))}
    </>
  )
}
getBasicStepContent = () => {
  const { classes } = this.props;
  const { chosenItem} = this.state;
  switch(chosenItem){
    case configJSON.ChemicalsText:
    case configJSON.PhytoProductsText:
    case configJSON.FertilizersText:
      return(
        <>
         <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
            <Grid item  md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.chemicalName}
                onChange={this.handleOnChange}
                placeholder={this.t(`${configJSON.productNamePlaceHolder}`)}
                name="chemicalName"
                data-test-id={'chemicalName'}
                errors={
                  this.state.error.chemicalName
                }
                />
            </Grid>
            <Grid item  md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.maxDosage}
                name="maxDosage"
                onChange={this.handleNumberOnChange}
                placeholder={this.t(`${configJSON.maxDosageTxt}`)}
                data-test-id={'maxDosage'}
                type="text"
                />
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <FormControl 
                className="select-outer" 
                variant="outlined" 
                //error ={this.state.error.chemicalQty!==""}
              >
                <InputLabel>
                {this.t(`${configJSON.quantityPlaceHolder}`)}
                </InputLabel>
                <Select
                  value={this.state.chemicalQty}
                  name="chemicalQty"
                  onChange={this.handleSelectChange}
                  label={this.t(`${configJSON.quantityPlaceHolder}`)}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="selectChemicalQty"
                >
                  {this.state.quantityUnits.map((unit:any)=>{
                    return(
                      <MenuItem value={unit.name} key={unit.id}>{unit.name}</MenuItem>
                    )
                  })}                
                  
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <FormControl 
                className="select-outer" 
                variant="outlined"
                //error ={this.state.error.chemicalArea!==""}
              >
                <InputLabel>
                 {this.t(`${configJSON.vloumeTxt}`)}
                </InputLabel>
                <Select
                  data-testId={"chemicalArea"}
                  value={this.state.chemicalVolume}   
                  name="chemicalVolume"
                  onChange={this.handleSelectChange}
                  label={this.t(`${configJSON.vloumeTxt}`)}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="selectChemicalArea"
                >
                  {this.state.areaUnits.map((area:any)=>{
                    return(
                      <MenuItem value={area.name} key={area.id}>{area.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            {chosenItem != configJSON.FertilizersText &&
            <Grid item md={12} lg={12} sm={12} xs={12}>
            <FreeSoloCreateOption
                options={this.state.targetList.map((val) => {
                  return {
                    id: val.id,
                    title: val.name ?? "Test2",
                  };
                })}
                addEditCallback={this.addTarget}
                addLinkTitle={this.t(`${configJSON.addNewTargetTxt}`)}
                noOptionTitle={this.t(`${configJSON.noTargetFountTxt}`)}
                label={this.t(`${configJSON.targetTxt}`)}
                id="Target"
                onChange={this.targetChangeHandler}
                value={this.state.target}
                deleteCallback={this.deleteTarget}
                errorMsgNoRecord={this.t(`${configJSON.noTargetCreatedTxt}`)}
                data-test-id="targetFreeSolo"
                notRequired={true}
                placeHolder={this.t(`${configJSON.searchTargetTxt}`)}
              />
            </Grid>
            }
          </Grid>
          {this.getAttachmentContent()}
        </> 
      )
    
    case configJSON.landText:
      return(
          <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
            <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.landName}
                onChange={this.handleOnChange}
                placeholder={this.t(`${configJSON.productNamePlaceHolder}`)}
                name="landName"
                data-test-id={'landName'}
                errors={
                  this.state.error.landName
                }
                />
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.landArea}
                name="landArea"
                onChange={this.handleNumberOnChange}
                placeholder={this.t(`${configJSON.areaTxt}`)}
                data-test-id={'landArea'}
                />
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <FormControl className="select-outer" variant="outlined">
                <InputLabel>
                  {this.t(`${configJSON.unitOfMeasurementTxt}`)}
                </InputLabel>
                <Select
                  value={this.state.landUnit}
                  name="landUnit"
                  onChange={this.handleSelectChange}
                  label={this.t(`${configJSON.unitOfMeasurementTxt}`)}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  data-test-id="selectLandUnit"
                >
                 {this.state.areaUnits.map((area:any)=>{
                    return(
                      <MenuItem value={area.name} key={area.id}>{area.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          
            
          </Grid>
      )
    case configJSON.vesselsText:
      return (
        <>
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={12} sm={12} xs={12}>
          <CustomInput
              data-test-id={'vesselName'}
              errors={
                this.state.error.landName
              }
              name="landName"
              onChange={this.handleOnChange}
              placeholder={this.t(`${configJSON.productNamePlaceHolder}`)}
              value={this.state.landName}
              />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
          <CustomInput
              value={this.state.vesselVol}
              name="vesselVol"
              onChange={this.handleNumberOnChange}
              placeholder={this.t(`${configJSON.vloumeTxt}`)}
              data-test-id={'vesselVol'}
              type="text"
              />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <FormControl className="select-outer" variant="outlined" error ={this.state.error.landUnit!==""}>
              <InputLabel>
              {this.t(`${configJSON.unitOfMeasurementTxt}`)}
              </InputLabel>
              <Select
                value={this.state.landUnit}
                name="landUnit"
                onChange={this.handleSelectChange}
                label={this.t(`${configJSON.unitOfMeasurementTxt}`)}
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} />
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                data-test-id="selectVesselQty"
              >
                {this.state.vesselUnits.map((unit:any)=>{
                  return(
                    <MenuItem value={unit.name} key={unit.id}>{unit.name}</MenuItem>
                  )
                })}                
                
              </Select>
              {this.state.error.landUnit !== "" && (
                <FormHelperText className="error-select">
                  {this.state.error.landUnit}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
       
        </Grid>
        {this.getAttachmentContent()}
      </> 
      )
        
  }
}

getAdditionalInfoContent = () => {
  const { classes } = this.props;
  const { chosenItem} = this.state;
  switch(chosenItem){
    case configJSON.ChemicalsText:
      return (
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          
          <Grid item  md={12} lg={12} sm={12} xs={12}>
          <CustomInput
              value={this.state.risk}
              name="risk"
              onChange={this.handleOnChange}
              placeholder={this.t(`${configJSON.riskTxt}`)}
              data-test-id={'risk'}
              />
          </Grid>
          <Grid item  md={12} lg={12} sm={12} xs={12}>
          <CustomInput
              value={this.state.chemicalDescription}
              name="chemicalDescription"
              onChange={this.handleOnChange}
              placeholder={this.t(`${configJSON.descriptionPlaceholder}`)}
              data-test-id={'chemicalDescription'}
              isMultiline
              rows={4}
              />
          </Grid>
          <Grid item  md={12} lg={12} sm={12} xs={12}>
          <CustomInput
                value={this.state.qtyStock}
                name="qtyStock"
                onChange={this.handleNumberOnChange}
                placeholder={this.t(`${configJSON.stockQuanPlaceholder}`)}
                data-test-id={'qtyStock'}
                errors={
                  this.state.error.qtyStock
                }
                />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
          <Grid item md={6} lg={6} sm={6} xs={12}>
          <FreeSoloCreateOption
              options={this.state.colourValue.map((val) => {
                return {
                  id: val.id,
                  title: val.name,
                };
              })}
              addEditCallback={this.addColor}
              addLinkTitle={this.t(`${configJSON.addColorTxt}`)}
              noOptionTitle={this.t(`${configJSON.noColorFoundTxt}`)}
              label={this.t(`${configJSON.colorPlaceHolder}`)}
              id="Color"
              onChange={this.colorChangeHandler}
              value={this.state.colour}
              deleteCallback={this.deleteColor}
              errorMsgNoRecord={this.t(`${configJSON.noColorCreatedTxt}`)}
              data-test-id="chemicalColor"
              notRequired={true}
              placeHolder={this.t(`${configJSON.searchColorTxt}`)}
              // errors={this.state.error.}
            />
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12}>
              {this.getConsumablesBrands()}
            </Grid>
          </Grid>
          {this.getConsumablesCommonFields()}
        </Grid>
      )
    case configJSON.PhytoProductsText:
    case configJSON.FertilizersText:
        return (
          <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
            {chosenItem != configJSON.FertilizersText &&
            <Grid item  md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.risk}
                name="risk"
                onChange={this.handleOnChange}
                placeholder={this.t(`${configJSON.riskTxt}`)}
                data-test-id={'risk'}
                />
            </Grid>
            }


            {chosenItem ==configJSON.FertilizersText && (
            <>
            <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <CustomInput
                  value={this.state.nContent}
                  name="nContent"
                  onChange={this.handleNumberOnChange}
                  placeholder={this.t(`${configJSON.nContentTxt}`)}
                  data-test-id={'nContent'}
                  type="text"
                  />
              </Grid>
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <CustomInput
                  value={this.state.pContent}
                  name="pContent"
                  onChange={this.handleNumberOnChange}
                  placeholder={this.t(`${configJSON.pContentTxt}`)}
                  data-test-id={'pContent'}
                  type="text"
                  />
              </Grid>
            </Grid>
            <Grid item  md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.kContent}
                name="kContent"
                onChange={this.handleNumberOnChange}
                placeholder={this.t(`${configJSON.kContentTxt}`)}
                data-test-id={'kContent'}
                type="text"
                />
            </Grid></>)}
            <Grid item  md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.chemicalDescription}
                name="chemicalDescription"
                onChange={this.handleOnChange}
                placeholder={this.t(`${configJSON.descriptionPlaceholder}`)}
                data-test-id={'chemicalDescription'}
                isMultiline
                rows={4}
                />
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
              <Grid item md={6} lg={6} sm={6} xs={12}>
              <CustomInput
                  value={this.state.qtyStock}
                  name="qtyStock"
                  onChange={this.handleNumberOnChange}
                  placeholder={this.t(`${configJSON.stockQuanPlaceholder}`)}
                  data-test-id={'qtyStock'}
                  errors={
                    this.state.error.qtyStock
                  }
                  />
              </Grid>
              <Grid item md={6} lg={6} sm={6} xs={12}>
                {this.getConsumablesBrands()}
              </Grid>
            </Grid>
            {this.getConsumablesCommonFields()}
          </Grid>
        )
    case configJSON.landText:
      return (
        <>
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={12} sm={12} xs={12}>
              <GoogleMapComponent respData={this.setMapUrl} latlng={this.state.mapLatLng} mapPlace={this.state.mapPlaceName}/>
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
                value={this.state.landDescription}
                name="landDescription"
                onChange={this.handleOnChange}
                placeholder={this.t(`${configJSON.descriptionPlaceholder}`)}
                data-test-id={'landDescription'}
                isMultiline
                rows={4}
                />
            </Grid>
 
        </Grid>
        {this.getAttachmentContent()}</>
      )
    case configJSON.vesselsText:
      return (
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">   
          <Grid item md={12} lg={12} sm={12} xs={12}>
          <CustomInput
              value={this.state.landDescription}
              name="landDescription"
              onChange={this.handleOnChange}
              placeholder={this.t(`${configJSON.descriptionPlaceholder}`)}
              data-test-id={'vesselDesc'}
              isMultiline
              rows={4}
              />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <FreeSoloCreateOption
                data-test-id="vesselBrand"
                addEditCallback={this.addBrand}
                options={this.state.brandList.map((val) => {
                  return {
                    title: val.attributes.name ?? "Test2",
                    id: val.id,
                  };
                })}
                addLinkTitle={this.t(`${configJSON.addNewBrandTxt}`)}
                onChange={this.brandChangeHandler}
                label={this.t(`${configJSON.brandTxt}`)}
                id="Brand"
                value={this.state.chemicalBrand}
                // errors={this.state.editError.jobTitle}
                deleteCallback={this.deleteBrand}
                notRequired={true}
                noOptionTitle={this.t(`${configJSON.noBrandTxt}`)}
                errorMsgNoRecord={this.t(`${configJSON.noBrandCreatedTxt}`)}
                placeHolder={this.t(`${configJSON.brandPlaceholder}`)}
              />
             
          </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12}>          
              <FreeSoloCreateOption
                options={this.state.materialList.map((val) => {
                  return {
                    id: val.id,
                    title: val.name,
                  };
                })}
                addEditCallback={this.addMaterial}
                addLinkTitle={this.t(`${configJSON.addNewMaterialTxt}`)}
                noOptionTitle={this.t(`${configJSON.noMaterialFoundTxt}`)}
                label={this.t(`${configJSON.materialTxt}`)}
                id="Material"
                onChange={this.materialChangeHandler}
                value={this.state.material}
                // errors={this.state.editError.jobTitle}
                deleteCallback={this.deleteMaterial}
                errorMsgNoRecord={this.t(`${configJSON.noMaterialCreatedTxt}`)}
                notRequired={true}
                data-test-id="vesselMaterial"
                placeHolder={this.t(`${configJSON.searchMaterialTxt}`)}
              />
            </Grid>
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <FreeSoloCreateOption
                options={this.state.vesselspec.map((val) => {
                  return {
                    id: val.id,
                    title: val.name ?? "Test2",
                  };
                })}
                addEditCallback={this.addSpecification}
                addLinkTitle={this.t(`${configJSON.addNewSpecificationTxt}`)}
                noOptionTitle={this.t(`${configJSON.noSpecificationFoundTxt}`)}
                label={this.t(`${configJSON.specificationsTxt}`)}
                id="Specifications"
                onChange={this.specChangeHandler}
                value={this.state.specification}
                // errors={this.state.editError.jobTitle}
                deleteCallback={this.deleteSpecification}
                errorMsgNoRecord={this.t(`${configJSON.noSpecificationCreatedTxt}`)}
                data-test-id="specifications"
                notRequired={true}
                // errors={this.state.error.target}
              />
          </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <CustomInput
                value={this.state.vesselSrlNo}
                name="vesselSrlNo"
                onChange={this.handleOnChange}
                placeholder={this.t(`${configJSON.serialPlaceholder}`)}
                data-test-id={'vesselSrlNo'}
                />
            </Grid>
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className={classes.rowSpacing}>
            <CustomDatePicker
                placeholder={this.t(`${configJSON.expirationDateTxt}`)}
                currentDate={this.state.vesselexpDate}
                onChange={this.vesselChangeHandler}
                onOpenPickNewDate={false}
                data-test-id="vesselexpDate"
                minDate={new Date()}
                // required={true}
              />
          </Grid>


        </Grid>
      )
  }
}

getHardwareStepContent = (step:number) => {
  switch(step){
    case 0:
      return (
        this.getMainStepContent()
      );
    case 1:
      return (<ToolsInfo 
        seletedItem={this.state.chosenItem} nextBtn={this.nextStep}
        stepNumber={this.state.addingsteps} backBtn={this.backStep}
      />)
    case 2:
      return (<ToolsAdditionalInfoWeb  stepNumber={this.state.addingsteps} 
        seletedItem={this.state.chosenItem}
        backBtn={this.backStep} goToSuccess={this.itemAddedSuccess}
      />)
  }
}
getThreeStepsContent = (step:number) => {
  const { classes } = this.props;
  switch(step){
    case 0:
      return (
        this.getMainStepContent()
      );
    case 1:
      return (
        <Box className={classes.basicInfoSection}>
          <Box className="heading-box">
            <Box>
              <Typography className={classes.heading} variant="h1">
                {this.t(`${configJSON.addingTitle}`)}
              </Typography>
              <Typography className={classes.subtitleText}>
                {this.t(`${configJSON.addingSubtext}`)}
              </Typography>
            </Box>
            <Box className="item-box" >
              {this.state.chosenItem}
            </Box>
          </Box>
          {this.getBasicStepContent()}
          <Box className={classes.formButtons}>
            <Button className="backBtn" onClick={this.backStep}
              data-testId={"back-btn"}
            >
              {this.t(`${configJSON.backButton}`)}
            </Button>
            <Button className="next-step-btn" onClick={this.nextStep}
              data-testId={"next-btn"}
            >
              {this.t(`${configJSON.nextButton}`)}
            </Button>
          </Box>
        </Box>)
    case 2:
      return (
        <Box className={classes.basicInfoSection}>
          <Box className="heading-box">
            <Box>
              <Typography className={classes.heading} variant="h1">
                {this.t(`${configJSON.additionalinfoTitle}`)}
              </Typography>
              <Typography className={classes.subtitleText}>
                {this.t(`${configJSON.additionalinfoTitleSubtext}`)} &nbsp; {this.state.chosenItem.endsWith('s') ? this.state.chosenItem.slice(0, -1).toLowerCase() : this.state.chosenItem.toLowerCase()}.
              </Typography>
            </Box>
            <Box className="item-box">
              {this.state.chosenItem}
            </Box>
          </Box>
          {this.getAdditionalInfoContent()}
          <Box className={classes.formButtons}>
            <Button className="backBtn" onClick={this.backStep}
              data-testId={"back-btn"}
            >
              {this.t(`${configJSON.backButton}`)}
            </Button>
            <Button className="next-step-btn" 
              onClick={this.createCatalogue}
              data-testId={"next-btn"}
            >
             {this.t(`${configJSON.nextButton}`)}
            </Button>
          </Box>
        </Box>)
  }
}
getConsumablesBrands = () => {
  return (
    <FreeSoloCreateOption
                options={this.state.brandList.map((val) => {
                  return {
                    id: val.id,
                    title: val.attributes.name ?? "Test2",
                  };
                })}
                addEditCallback={this.addBrand}
                addLinkTitle={this.t(`${configJSON.addNewBrandTxt}`)}
                noOptionTitle={this.t(`${configJSON.noBrandTxt}`)}
                label={this.t(`${configJSON.brandTxt}`)}
                id="Brand"
                onChange={this.brandChangeHandler}
                value={this.state.chemicalBrand}
                deleteCallback={this.deleteBrand}
                errorMsgNoRecord={this.t(`${configJSON.noBrandCreatedTxt}`)}
                notRequired={true}
                data-test-id="chemicalBrand"
                placeHolder={this.t(`${configJSON.brandPlaceholder}`)}
                wrapperClass="brandWrapper"
              />
  )

}

getConsumablesCommonFields = () => {
  const {classes} = this.props;
  return (
    <>
    <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            <Grid item md={6} lg={6} sm={6} xs={12}>
            <CustomInput
              value={this.state.lotNo}
              name="lotNo"
              onChange={this.handleOnChange}
              placeholder={this.t(`${configJSON.lotNumberTxt}`)}
              data-test-id={'lotNo'}
              type="textarea"
              />
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12}>
            <CustomDatePicker
              placeholder={this.t(`${configJSON.expirationDateTxt}`)}
              currentDate={this.state.expiryDate}
              onChange={this.changeExpiryDate}
              onOpenPickNewDate={false}
              data-test-id="expiryDate"
              minDate={new Date()}
              // required={true}
            />
            </Grid>
          </Grid>
      <Grid item  md={12} lg={12} sm={12} xs={12} className={classes.withHelperText}>
      <CustomInput
                value={this.state.cost}
                name="cost"
                onChange={this.handleNumberOnChange}
                placeholder={this.t(`${configJSON.costTxt}`)}
                data-test-id={'cost'}
                startAdornment={
                    <InputAdornment position="start">
                      <p style={{ color: "#94A3B8" }}>$</p>
                    </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" style={{ color: "#94A3B8" }}>
                   {this.t(`${configJSON.perTxt}`)} {this.state.chemicalQty}
                  </InputAdornment>
                }
                />
                <FormHelperText className="helper-text">
                  {this.t(`${configJSON.quantityInfoTxt}`)}
              </FormHelperText>
      </Grid>
      </>
  )

}
 // Customizable Area End

render() {
    // Customizable Area Start
    const {classes} = this.props;
    const { addingsteps } = this.state;
    const labels:string[] = this.state.itemStepsLabel;
    // Customizable Area End
    return (
        // Customizable Area Start
        <Box className={classes.stepRootWrapper}>
           {this.state.isLoading && <WebLoader />}
            <Drawer variant="permanent" anchor="left"
                classes={{
                    paper: classes.drawerStyle,
                }}
            >
                <Box className={classes.sidebar}>
                    <Box className="back" data-testId={"back-Icon"}
                    onClick={()=>this.goBack()}>
                        <i>
                            <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            >
                            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                            </svg>
                        </i>
                    </Box>
                    <Box className={classes.stepper}>
                        <Stepper activeStep={this.state.addingsteps} orientation="vertical" 
                        data-testId="stepContent"
                        >
                            {labels.map((label:string, index) => {
                            const props = { completed: false };
                            const labelProps = {};
                            if (this.isStepCompleted(index)) {
                            props.completed = true;
                            }
                            return (
                                <Step
                                key={`step-${index.toString()}`}
                                {...props}
                                className={`step-root ${
                                    addingsteps == index ? "active-step-root" : ""
                                }`}
                                >
                                <StepLabel
                                    {...labelProps}
                                    StepIconComponent={this.ColorlibStepIcon}
                                    data-testId="step-counts"
                                >
                                    <Box className="step-no">{addingsteps > index ? "Completed" : `Step ${index + 1}`} </Box>
                                    <Box className="step-label" data-testId={"stepLabels"}>{label}</Box>
                                </StepLabel>
                                </Step>
                            )
                            })}
                        </Stepper>
                    </Box>
                </Box> 
            </Drawer>
            <Box className="step-content-root">
                <Box className={classes.mobileBackArrow}>
                  <div data-test-id="goBack" onClick={()=>this.goBack()}>
                      <i>
                          <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          >
                          <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                          </svg>
                      </i>
                  </div>
                    <Box className="step-box">
                      <Box className="itemBox" data-testId={"itemText"}>
                        {this.state.chosenItem}
                      </Box>
                      <Box className="adding-steps">
                      {addingsteps}/{labels.length}
                      </Box>
                    </Box>
                  </Box>
                {addingsteps === labels.length ? (
                <Box className="step-content">
                    <Typography className="heading">
                        {this.t(`${configJSON.finishStepMsg}`)}
                    </Typography>
                </Box>
                ) : (
                  <>
                <Box className="step-content" >
                    {this.state.chosenItem === configJSON.wineText && this.getStepContent(addingsteps)}
                    {this.state.chosenItem === configJSON.spiritText && this.getStepContent(addingsteps)}
                    {this.state.chosenItem === configJSON.beerText && this.getBeerStepContent(addingsteps)}
                    {this.state.chosenItem === configJSON.otherText && this.getOtherStepContent(addingsteps)}
                    {this.state.chosenItem === configJSON.hardwareText && this.getHardwareStepContent(addingsteps)}
                    {this.state.chosenItem === configJSON.machineText && this.getHardwareStepContent(addingsteps)}
                    {this.threeStepCategories.includes(this.state.chosenItem) && this.getThreeStepsContent(addingsteps)}
                </Box>
                <ConfirmActionModal
                  isOpen={this.state.isDeletingSelctor}
                  handleClose={this.deleteSelectorModalClose}
                  modalConfirmAction={this.deleteSelectorApi}
                  modalMessage={`Are you sure you want to delete ${this.state.selectorType} ?`}
                  confirmBtnTxt="Delete"
                  modalHeading={`Delete ${this.state.selectorType}`}
                  data-test-id="confirmDeleteSelector"
                />

              <ConfirmActionModal
                  isOpen={this.state.isDeletingTarget}
                  handleClose={this.deleteTargetModalClose}
                  modalConfirmAction={this.deleteTargetApi}
                  deleteTeamId={this.state.deleteTargetId}
                  modalMessage="Are you sure you want to delete Target ?"
                  confirmBtnTxt="Delete"
                  modalHeading="Delete Target"
                  data-test-id="confirmDeleteTarget"
                />

               <ConfirmActionModal
                  isOpen={this.state.isDeletingBrand}
                  handleClose={this.deleteBrandModalClose}
                  modalConfirmAction={this.deleteBrandApi}
                  deleteTeamId={this.state.deleteBrandId}
                  modalMessage="Are you sure you want to delete Brand ?"
                  confirmBtnTxt="Delete"
                  modalHeading="Delete Brand"
                  data-test-id="confirmDeleteBrand"
                />
                </>
                )}
            </Box>
        </Box>
        // Customizable Area End
    );
}
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(AddItem);
// Customizable Area End