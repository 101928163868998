// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { customStyles } from "./ForgotPassword.web";
import { WithStyles } from "@material-ui/core/styles";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
}

interface S {
  email: string;
  error: string;
  message: string;
  password: string;
  passwordError: string;
  emailSuccessMsg: string;
  isPasswordValid: boolean;
  isPasswordVisible: boolean;
  open: boolean;
  passwordToken: string;
  isResend: boolean;
  resendSec: number;
  intervalId: NodeJS.Timeout | null;
  showTimer: boolean;
  disable: boolean;
  helperTextClass: string | undefined;
  emailCheked: boolean;
}
interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  forgotPasswordApiID: string = "";
  resetPasswordApiID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      email: "",
      password: "",
      error: "",
      passwordError: "",
      emailSuccessMsg: "",
      message: "",
      isPasswordVisible: false,
      isPasswordValid: false,
      open: false,
      passwordToken: "",
      isResend: false,
      resendSec: 15,
      intervalId: null,
      showTimer: false,
      disable: false,
      helperTextClass: undefined,
      emailCheked: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  zeroPad = (num: any, places: any) => String(num).padStart(places, "0");

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  togglePasswordVisibility = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.forgotPasswordApiID) {
        // user is logged in 
        if (responseJson.error) {
          this.setState({
            error: "Please Contact Support for help",
            helperTextClass: "Mui-default",
          });
        } else {
          this.setState({
            emailSuccessMsg: "An email has been sent",
            helperTextClass: "",
            isResend: true,
            showTimer: true,
            resendSec: 60,
            disable: true,
            emailCheked: true,
          });
        }
      } else if (apiRequestCallId === this.resetPasswordApiID) {
        if (responseJson.error) {
          this.setState({
            passwordError: responseJson.error[0],
          });
        } else {
          this.props.navigation.navigate("PasswordResetSuccess");
        }
      } else {
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.email !== this.state.email) {
      this.setState({
        error: "",
        emailSuccessMsg: "",
      });
    }
    if (prevState.password !== this.state.password) {
      this.setState({
        passwordError: "",
      });
    }
    if (prevState.showTimer !== this.state.showTimer && this.state.showTimer) {
      const newIntervalId = setInterval(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            resendSec: prevState.resendSec - 1,
          };
        });
      }, 1000);
      this.setState({ intervalId: newIntervalId });
    }
    if (prevState.resendSec !== this.state.resendSec) {
      if (this.state.resendSec == 0) {
        if (this.state.intervalId != null) {
          clearInterval(this.state.intervalId);
          this.setState({ showTimer: false, disable: false });
        }
      }
    }
  }

  getAllUrlParams = (url: string) => {
    let queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    let obj: any = {};

    if (queryString) {
      queryString = queryString.split("#")[0];

      let arr = queryString.split("&");

      arr.forEach((element) => {
        let a = element.split("=");

        let paramName = a[0];
        let paramValue = typeof a[1] === "undefined" ? true : a[1];

        paramName = paramName.toLowerCase();

        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        }
      });
    }

    return obj;
  };
  async componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("token")) {
      this.setState({
        passwordToken: this.getAllUrlParams(queryString).token as string,
      });
    }
    const lang = localStorage.getItem("lang") || "en"; 
    await(i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  validateEmail = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.exec(this.state.email);
  };

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };
  navigateLogin = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  };
  handlePassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };
  handleForgot = (e: any) => {
    if (e) {
      e.preventDefault();
    }

    if (this.state.isResend && this.state.resendSec > 0) {
      return;
    }
    let errorFlag = false;
    let emailErr = this.state.error;
    if (this.state.email.trim() === "") {
      emailErr = "Email should not be blank";
      errorFlag = true;
    } else if (!this.validateEmail()) {
      emailErr = "Invalid email";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        error: emailErr,
        helperTextClass: "",
      });
      return;
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.forgotpasswordAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          email: this.state.email,
          device_type: "web",
        },
      })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleReset = (e: any) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.isPasswordValid) {
      this.setState({
        passwordError: "Password does not meet requirements",
      });
      return;
    }
    if (this.state.passwordToken == "") {
      this.setState({ passwordError: "Invalid token" });
      return;
    }
    const header = {
      "Content-Type": configJSON.resetPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetpasswordAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          token: this.state.passwordToken,
          new_password: this.state.password,
        },
      })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  isValidPassword = (isValid: boolean) => {
    this.setState({ isPasswordValid: isValid });
  };
  
  handleBack = () => {
  const routeMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
  routeMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
  routeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(routeMsg);
  };
}
// Customizable Area End
