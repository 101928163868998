Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EmailNotifications";
exports.labelBodyText = "EmailNotifications Body";
exports.marketingProductsEndPoint = "/bx_block_contactslist/product_list";
exports.methodTypeGET = "GET";
exports.methodTypePOST = "POST";
exports.getDirectEmailsEndPoint = "/bx_block_email_notifications/direct_mails?";
exports.createDirectEmailsEndPoint = "/bx_block_email_notifications/direct_mails";
exports.fetchAllContacts = "bx_block_contactslist/contacts"
exports.validateAccountBrevo = "bx_block_email_notifications/validate_brevo_api_key"
exports.viewSingleMail = "/bx_block_email_notifications/direct_mails/";
exports.btnExampleTitle = "CLICK ME";
exports.directEmailsTxt="Direct Emails";
exports.emailsTxt="Emails";
exports.emailsTxtSingular="Email";
exports.dateRangeTxt="Date Range";
exports.toContactGroupTxt="To (contact or group)";
exports.contactTxt="contact";
exports.columnsTxt = "Columns";
exports.newestoold= "Newest to oldest";
exports.oldtonew="Oldest to newest";
exports.recipientTxt="Recipients";
exports.subjectTxt="Subject";
exports.messageTxt="Message";
exports.dateTxt="Date";
exports.attachmentTxt="Attachments";
exports.actionsTxt="Actions";
exports.viewTxt="View";
exports.createEmailTxt="Create Email";
exports.composeEmailTxt="Compose Email";
exports.composeTxt="Compose";
exports.composeEmailSubTxt="Let’s compose an email.";
exports.fromTxt="From";
exports.toTxt="To";
exports.CCTxt="CC";
exports.BCCTxt="BCC";
exports.subjectTxt="Subject";
exports.addProductTxt="Add Product";
exports.productsTxt="Products";
exports.itemsTxt="Items";
exports.totalAmount="Total Amount";
exports.clickHereTxt="Click here to";
exports.browseTxt="browse";
exports.yourPictureTxt="your picture or document";
exports.addAttachmentsTxt="Add attachments";
exports.sendNowTxt="Send Now";
exports.draftMsgTxt="Do you want to save your draft?";
exports.saveAsDraftText="Save as Draft";
exports.serachTypeTxt="Start typing to search...";
exports.hasAttachmentsTxt="Has Attachments";
exports.sortByTxt="Sort by"
exports.addToEmail="Add to Email";
exports.productsHeading="Choose Items";
exports.productsSubHeading="Please choose the items you want to attach.";
exports.getProfileList="/account_block/profile_list";
exports.getSenderList = "/bx_block_email_notifications/get_senders_list";
exports.emailAddressTxt="Email Address";
exports.fromemailAddressTxt="From";
exports.baseConfig = require("../../../framework/src/config"); 
exports.noEmailText = 'No Direct Emails';
exports.noEmailDescription = 'Compose a new email to a contact or group';
exports.backTxt="Back";
// Customizable Area End