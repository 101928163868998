// Customizable Area Start
import React from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CustomisableUserProfilesController from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    width: "auto",
    minWidth: "156px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 6px",
      minHeight: "46px",
    },
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    width: "auto",
    minWidth: "94px",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
  sidebarNav: {
    padding: "0",
    "& .MuiListItem-root": {
      padding: "0",
      marginBottom: "24px",
      "&:last-child": {
        marginBottom: "0",
      },
      "&.active": {
        "& .sidebar-link": {
          background: "#F2F8FC",
        },
      },
      "& .sidebar-link": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        padding: "8px 20px",
        background: "transparent",
        borderRadius: "6px",
        textDecoration: "none",
        [theme.breakpoints.down("xs")]: {
          padding: "16px 20px",
          width: "100%",
        },
      },
      "& .MuiListItemText-root": {
        margin: "0",
      },
      "& .MuiTypography-root": {
        font: "inherit",
        letterSpacing: "0",
      },
    },
  },
}));

function CustomProfileSidebar(props: any) {
  const classes = useStyles();
  const pageLink =  window.location.pathname;
  const userSessionData = sessionStorage.getItem("userData");
  const userType = userSessionData && JSON.parse(userSessionData);
  return (
    <Box className="drawer-inner">
      <Box className="sidebar-heading">
        <Box className="back-btn-wrap" onClick={props.toggleMenu}>
          <i>
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
            </svg>
          </i>
        </Box>
        <Typography className="heading" variant="h1">
        {props.t(`${props.title || "Profile"}`)}
        </Typography>
      </Box>
      <List component="nav" className={classes.sidebarNav}>
        <ListItem className={pageLink==="/profile"? "active" : ""}>
          <Link className="sidebar-link" to="/profile">
            {props.t("My Profile")}
          </Link>
        </ListItem>
        <ListItem>
          <Link className="sidebar-link" to="/create-team">
            {props.t("My Teams")}
          </Link>
        </ListItem>
        <ListItem className={pageLink==="/live-chat"? "active" : ""}>
          <Link className="sidebar-link" to="/live-chat">            
            {props.t("Live Chat")}
          </Link>
        </ListItem>
        <ListItem>
          <Link className="sidebar-link" to="/order-history">
            {props.t("Order History")}
          </Link>
        </ListItem>
        <ListItem>
          <Link className="sidebar-link" to="/#">
            {props.t("Schedule")}
          </Link>
        </ListItem>
        {userType?.data?.attributes?.job_type !== "employee" && 
         <ListItem>
         <Link className="sidebar-link" to="/timesheet">
           {props.t("Timesheet")}
         </Link>
       </ListItem>
        }       
        {userType.data.attributes.job_type === "owner" && 
        <>
        <ListItem>
          <Link className="sidebar-link" to="/#">            
            {props.t("Roles & Permissions")}
          </Link>
        </ListItem>
        <ListItem>

        <Link className="sidebar-link" to="/connect">  
            {props.t("Integrations")}
          </Link>
        </ListItem>
        </>        
        }
      </List>
    </Box>
  );
}

export default CustomProfileSidebar;
// Customizable Area End
