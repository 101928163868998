export const emptyMyOrdersIcon = require("../assets/emptyMyOrdersIcon.png");
export const activeDash = require("../assets/Rectangle.svg");
export const inActiveDash = require("../assets/Rectangle-inactive.svg");
export const shoppingCartIcon = require("../assets/shopping-cart.svg");
export const defaultView = require("../assets/pana.png");
export const deliverd = require("../assets/deliverd.png");
export const checkCircle = require("../assets/check-circle.png");
export const keyboardBack = require("../assets/keyboard-backspace.png");
export const attachment = require("../assets/attachment.png");
export const warningIcon = require("../assets/warning-red.png");
export const back = require("../assets/Path.png");
export const defaultServiceImage = require("../assets/default_service_image.png");