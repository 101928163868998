export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const minimizeGray = require("../assets/minimize-gray.png");
export const closeGray = require("../assets/close-gray.png");
export const attachment = require("../assets/attachment.png");
export const calendarRange = require("../assets/calendar-range.png");
export const menu = require("../assets/menu.png");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
export const uploadImg = require("../assets/upload-icon.png");
export const viewColumn = require("../assets/view_column.svg");
export const sortVariant = require("../assets/sort_variant.svg");
export const editIcon = require("../assets/edit.png");
export const copyIcon = require("../assets/copy.png");
export const deleteIcon = require("../assets/delete.png");
export const taskIcon = require("../assets/task.png");
export const expand = require("../assets/expand.png");
export const checkGreen = require("../assets/check-green.png");
export const attachGray = require("../assets/attach.png");
export const editGray = require("../assets/edit-gray.png");
export const contentCopy = require("../assets/content-copy.png");
export const deleteOutline = require("../assets/delete-outline.png");
export const fileTree = require("../assets/file-tree.png");
export const calendarGray = require("../assets/calendar-gray.png");
export const emptyDeals = require("../assets/empty-deals.jpeg");
export const searchDeals = require("../assets/search-deals.svg");
export const minimizeDeal = require("../assets/minimize-deal-icon.png");
export const cheveronDown = require("../assets/chevron-down.png");
export const productExample = require("../assets/product_example.png");
export const checked = require("../assets/ic_check_box_outline_blank (1).png"); 
export const clipboardCheckOutline = require("../assets/clipboard_check_outline.png");
export const close = require("../assets/close.png");
export const deleteGray= require("../assets/delete-gray.png");
export const productExampleImg = require("../assets/product-img.png");
export const attachmentsDeals = require("../assets/attachment-img1.png");
export const download = require("../assets/file-download.png");
export const plusCircle = require("../assets/plus-circle.png");
export const editCircle = require("../assets/edit-circle.png");
export const checkCircle = require("../assets/check-circle.png");
export const arrowULeft = require("../assets/arrow-u-left.png");
export const imgCreateTaskFilter = require("../assets/createTask_filter.png");
export const downloadGray = require("../assets/download-gray.png");
export const stageChanged = require("../assets/stage-changed.svg");

