import React from "react";
// Customizable Area Start
import {
  Modal,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  InputAdornment,
  List,
  ListItem,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FillOrderDetailsController, {
  FileData,
  Props,
  Question,
  configJSON,
} from "./FillOrderDetailsController.web";
import { attachment, defaultView, keyboardBack } from "./assets";
import CustomInputContent from "../../../components/src/CustomInput.web";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ViewOrderDetails } from "./ViewOrderDetails.web";
// Customizable Area End

export class FillOrderDetails extends FillOrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, handleCloseFillDetails, isFillDetails } = this.props;
    const {
      questionsData,
      selectedOptions,
      selectedRadioOption,
      viewProductData,
      isFillModalOpen,
      currentItemIndex,
      openOrderDetails,
    } = this.state;
    const products = viewProductData.attributes.order_items.data;
    const currentProduct = products[currentItemIndex];
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Modal
          open={isFillDetails}
          onClose={handleCloseFillDetails}
          className={classes.modalBoxCenter}
        >
          <Box className={classes.fillOrderDetailsModal}>
            <Box className="view-container">
              <Box className="header-container-2">
                <Box className="modal-title-box">
                  <Typography className="title-txt">
                    {this.translationEvent(`${configJSON.fillDetailsHeading}`)}
                  </Typography>
                  <Typography className="title-sub-txt">
                    {this.translationEvent(`${configJSON.fillDetailsSubTxt1}`)}
                  </Typography>
                </Box>
                <Box className="heading-bar">
                  <CloseIcon
                    className="close-icon"
                    onClick={handleCloseFillDetails}
                  />
                </Box>
              </Box>
              <Typography className="search-box">
                <CustomInputContent
                  type="text"
                  label=""
                  value={this.state.searchValue}
                  placeholder={this.translationEvent(`${configJSON.searchTxt}`)}
                  onChange={(event: { target: { value: string } }) =>
                    this.handleSearch(event.target.value)
                  }
                  data-test-id="searchValue"
                  startAdornment={
                    <InputAdornment position="end" style={{ color: "#94A3B8" }}>
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Typography>
              <Box className="price-container">
                <Typography className="price-item-txt">
                  {products.length > 1
                    ? `${products.length} ${this.translationEvent("Products")}`
                    : `${products.length} ${this.translationEvent("Product")}`}
                </Typography>
                <Typography className="price-item-txt">
                  <FormControl className="select-control sortby-control">
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: `dropdown-sort ${classes.dropdownStyle}`,
                        },
                      }}
                      displayEmpty
                      defaultValue={this.translationEvent("Sort by")}
                      inputProps={{ IconComponent: () => null }}
                      renderValue={(value) => {
                        return (
                          <Box>
                            {value}
                            <ExpandMoreIcon />
                          </Box>
                        );
                      }}
                      data-test-id="sort-by-input"
                    >
                      <MenuItem value={this.translationEvent("Sort by")}>
                        {this.translationEvent("Sort by")}
                      </MenuItem>
                      <MenuItem value="A-Z">
                        {this.translationEvent("A-Z")}
                      </MenuItem>
                      <MenuItem value="Z-A">
                        {this.translationEvent("Z-A")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Typography>
              </Box>
              {products.map((item: any) => {
                const { thumbnails, title } =
                  item.attributes.catalogue_service.data.attributes;
                return (
                  <Box key={item.attributes.id}>
                    <Box className="product-container">
                      <img
                        className="image"
                        src={this.getThumbnailUrl(thumbnails, defaultView)}
                        alt="imgae"
                      />
                      <Box className="title-container">
                        <Typography className="title">{title}</Typography>
                        <Typography className="sub-title">
                          {item.attributes.quantity} X {item.attributes.price}
                        </Typography>
                      </Box>
                      <Box className="price-container">
                        <Typography className="price-item-txt">
                          ${item.attributes.quantity * item.attributes.price}
                          .00
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="line" />
                  </Box>
                );
              })}
            </Box>
            <Box className="button-container">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                onClick={() => {
                  this.handleFillDetailsClick(currentProduct.id);
                }}
                data-test-id="handleFillDetailsClick"
              >
                {currentProduct?.attributes?.submission_status === true
                  ? this.translationEvent(`${configJSON.viewFieldDetailsTxt}`)
                  : this.translationEvent(`${configJSON.fillDetailsHeading}`)}
              </Button>
            </Box>
          </Box>
        </Modal>
        {isFillModalOpen && (
          <Modal
            open={isFillModalOpen}
            onClose={this.handleFillDetailsClose}
            className={classes.modalBoxCenter}
          >
            <Box className={classes.fillOrderDetailsModal}>
              <Box className="view-container">
                <Box className="header-container">
                  <Box className="modal-title-box">
                    <img
                      className="back-image"
                      src={keyboardBack}
                      alt="back"
                      onClick={this.handleOrderDetails}
                      data-test-id="handleOrderDetails"
                    ></img>
                    <Typography className="title-txt">
                      {this.translationEvent(
                        `${configJSON.orderDetailsHeading}`
                      )}
                    </Typography>
                  </Box>
                  <Box className="heading-bar">
                    <Typography className="stepper-count">
                      {currentItemIndex + 1}/
                      {viewProductData.attributes.order_items.data.length}
                    </Typography>
                    <CloseIcon
                      className="close-icon"
                      onClick={this.handleFillDetailsClose}
                    />
                  </Box>
                </Box>
                <Box className="header-container-2">
                  <Box className="modal-title-box">
                    <Typography className="title-txt">
                      {this.translationEvent(
                        `${configJSON.fillDetailsHeading}`
                      )}
                    </Typography>
                    <Typography className="title-sub-txt">
                      {this.translationEvent(`${configJSON.fillDetailsSubTxt}`)}
                    </Typography>
                  </Box>
                </Box>
                <Typography className="title-sub-txt">
                  {this.translationEvent(`${configJSON.youOrdertxt}`)}
                </Typography>
                <Box className="product-container-2">
                  <img
                    className="image"
                    src={
                      this.getAttributesThumbnailUrl(currentProduct, defaultView)
                    }
                    alt="imgae"
                  />
                  <Box className="title-container">
                    <Typography className="title">
                      {
                        currentProduct.attributes.catalogue_service.data
                          .attributes.title
                      }
                    </Typography>
                    <Typography className="sub-title">
                      {currentProduct.attributes.quantity} X{" "}
                      {currentProduct.attributes.price}
                    </Typography>
                  </Box>
                  <Box className="price-container">
                    <Typography className="price-item-txt">
                      $
                      {currentProduct.attributes.quantity *
                        currentProduct.attributes.price}
                      .00
                    </Typography>
                  </Box>
                </Box>
                {currentProduct.attributes.answers === null &&
                currentProduct.attributes.submission_status === false
                  ? questionsData.map((question: Question) => {
                      return (
                        <Box key={question.attributes.id}>
                          {question.attributes.question_type ===
                            "Short Text" && (
                            <>
                              <Typography className="questions-txt">
                                {question.attributes.title}
                              </Typography>
                              <CustomInputContent
                                type="text"
                                name="shortQuestionInput"
                                value={this.state.shortQuestionInput}
                                onChange={(e: any) =>
                                  this.handleAllInputChange(
                                    e,
                                    question.attributes.id
                                  )
                                }
                                data-test-id="shortQuestionInput"
                                placeholder="Text"
                              />
                            </>
                          )}

                          {question.attributes.question_type ===
                            "Single Choice" && (
                            <>
                              <Typography className="questions-txt">
                                {question.attributes.title}
                              </Typography>
                              <FormControl
                                component="fieldset"
                                className="radioFormControl"
                              >
                                <RadioGroup
                                  name="paymentType"
                                  className="radioFormControl"
                                  value={selectedRadioOption}
                                  onChange={(e: any) =>
                                    this.handleRadioChange(
                                      e,
                                      question.attributes.id
                                    )
                                  }
                                  data-test-id="handleRadioChange"
                                >
                                  {question.attributes.options &&
                                    question.attributes.options
                                      .filter((option) => option !== "")
                                      .map((opt: string) => (
                                        <FormControlLabel
                                          key={opt}
                                          value={opt}
                                          control={<Radio />}
                                          label={opt}
                                        />
                                      ))}
                                </RadioGroup>
                              </FormControl>
                            </>
                          )}

                          {question.attributes.question_type === "Number" && (
                            <>
                              <Typography className="questions-txt">
                                {question.attributes.title}
                              </Typography>
                              <CustomInputContent
                                type="number"
                                name="numberInput"
                                value={this.state.numberInput}
                                onChange={(e: any) =>
                                  this.handleAllInputChange(
                                    e,
                                    question.attributes.id
                                  )
                                }
                                data-test-id="numberInput"
                                placeholder="Number"
                              />
                            </>
                          )}

                          {question.attributes.question_type ===
                            "Long Text" && (
                            <>
                              <Typography className="questions-txt">
                                {question.attributes.title}
                              </Typography>

                              <CustomInputContent
                                type="text"
                                name="longQuestionInput"
                                value={this.state.longQuestionInput}
                                onChange={(e: any) =>
                                  this.handleAllInputChange(
                                    e,
                                    question.attributes.id
                                  )
                                }
                                data-test-id="longQuestionInput"
                                placeholder="Text"
                                rows={5}
                                isMultiline
                              />
                            </>
                          )}

                          {question.attributes.question_type ===
                            "Multiple Choice" && (
                            <>
                              <Typography className="questions-txt">
                                {question.attributes.title}
                              </Typography>
                              <Box className="checkbox-container">
                                {question.attributes.options &&
                                  question.attributes.options
                                    .filter((option) => option !== "")
                                    .map((opt: string) => {
                                      return (
                                        <FormControlLabel
                                          key={opt}
                                          className="checkboxFormControl"
                                          control={
                                            <Checkbox
                                              checked={selectedOptions.includes(
                                                opt
                                              )}
                                              onChange={() =>
                                                this.handleCheckboxChange(
                                                  opt,
                                                  question.attributes.id
                                                )
                                              }
                                              value={opt}
                                              data-test-id="handleCheckboxChange"
                                            />
                                          }
                                          label={opt}
                                        />
                                      );
                                    })}
                              </Box>
                            </>
                          )}

                          {question.attributes.question_type ===
                            "Attachments" && (
                            <>
                              <Typography className="questions-txt">
                                {question.attributes.title}
                              </Typography>

                              <CustomInputContent
                                type="text"
                                label={this.translationEvent(
                                  `${configJSON.addAttachmentsTxt}`
                                )}
                                name="Add attachment"
                                onClick={this.onBrowse}
                                data-test-id={`onBrowse`}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <img
                                      className="attachment-img"
                                      src={attachment}
                                      alt="attachmenticon"
                                    />
                                  </InputAdornment>
                                }
                              />
                              <input
                                type="file"
                                ref={this.fileRef}
                                multiple
                                onChange={(e) => {
                                  if (e.preventDefault) {
                                    e.preventDefault();
                                  }
                                  this.handleOnDrop(
                                    e.target.files,
                                    question.attributes.id
                                  );
                                }}
                                hidden
                                data-test-id="fileInput"
                              />
                              {this.state.filesUploaded !== null &&
                                this.state.filesUploaded.length > 0 && (
                                  <List className={classes.uploadFileList}>
                                    {this.state.filesUploaded.map(
                                      (file: FileData, index: number) => (
                                        <ListItem key={file.name}>
                                          <Box className="file-info">
                                            <Box className="file-name">
                                              {file.name}
                                            </Box>
                                            <Link
                                              className="close"
                                              to="#"
                                              data-test-id="removeFileBtn"
                                              onClick={() =>
                                                this.cancelParsingAction(index)
                                              }
                                            >
                                              <CloseIcon />
                                            </Link>
                                          </Box>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                )}
                            </>
                          )}
                        </Box>
                      );
                    })
                  : currentProduct.attributes.answers.data.map(
                      (question: any) => {
                        const { title, question_type, id, options } =
                          question.attributes.product_question.data.attributes;
                        return (
                          <Box key={id}>
                            {question_type === "Short Text" && (
                              <>
                                <Typography className="questions-txt">
                                  {title}
                                </Typography>
                                <CustomInputContent
                                  type="text"
                                  name="shortQuestionInput"
                                  value={
                                    question.attributes.correct_answer[
                                      "Short Text"
                                    ]
                                  }
                                  placeholder="Text"
                                />
                              </>
                            )}

                            {question_type === "Single Choice" && (
                              <>
                                <Typography className="questions-txt">
                                  {title}
                                </Typography>
                                <FormControl
                                  component="fieldset"
                                  className="radioFormControl"
                                >
                                  <RadioGroup
                                    name="paymentType"
                                    className="radioFormControl"
                                    value={
                                      question.attributes.correct_answer[
                                        "Single Choice"
                                      ]
                                    }
                                  >
                                    {options &&
                                      options
                                        .filter((option: any) => option !== "")
                                        .map((opt: string) => (
                                          <FormControlLabel
                                            key={opt}
                                            value={opt}
                                            control={<Radio />}
                                            label={opt}
                                          />
                                        ))}
                                  </RadioGroup>
                                </FormControl>
                              </>
                            )}

                            {question_type === "Number" && (
                              <>
                                <Typography className="questions-txt">
                                  {title}
                                </Typography>
                                <CustomInputContent
                                  type="number"
                                  name="numberInput"
                                  value={
                                    question.attributes.correct_answer["Number"]
                                  }
                                  placeholder="Number"
                                />
                              </>
                            )}

                            {question_type === "Long Text" && (
                              <>
                                <Typography className="questions-txt">
                                  {title}
                                </Typography>

                                <CustomInputContent
                                  type="text"
                                  name="longQuestionInput"
                                  value={
                                    question.attributes.correct_answer[
                                      "Long Text"
                                    ]
                                  }
                                  placeholder="Text"
                                  rows={5}
                                  isMultiline
                                />
                              </>
                            )}

                            {question_type === "Multiple Choice" && (
                              <>
                                <Typography className="questions-txt">
                                  {title}
                                </Typography>
                                <Box className="checkbox-container">
                                  {options &&
                                    options
                                      .filter((option: any) => option !== "")
                                      .map((opt: string) => {
                                        const correctAnswers = question.attributes.correct_answer["Multiple Choice"].split(",").map((answer: string) => answer.trim());
                                        const isChecked = correctAnswers.includes(opt.trim()); 
                                      
                                        return (
                                          <FormControlLabel
                                            key={opt}
                                            className="checkboxFormControl"
                                            control={
                                              <Checkbox
                                                checked={isChecked} 
                                                value={opt}
                                              />
                                            }
                                            label={opt}
                                          />
                                        );
                                      })}
                                </Box>
                              </>
                            )}

                            {question_type === "Attachments" && (
                              <>
                                <Typography className="questions-txt">
                                  {title}
                                </Typography>
                                <List className={classes.uploadFileList}>
                                  {question.attributes.attachments.map(
                                    (file: {
                                      id: number;
                                      filename: string;
                                    }) => (
                                      <ListItem key={file.id}>
                                        <Box className="file-info">
                                          <Box className="file-name">
                                            {file.filename}
                                          </Box>
                                        </Box>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </>
                            )}
                          </Box>
                        );
                      }
                    )}

                <Button
                  className={
                    currentProduct.attributes.submission_status === true
                      ? `primary-btn ${classes.primaryButton}`
                      : `secondary-btn ${classes.secondaryButton}`
                  }
                  data-test-id="skip-product"
                  onClick={() => this.handleNextProduct()}
                  style={{
                    display:
                      currentItemIndex !==
                      viewProductData.attributes.order_items.data.length - 1
                        ? "block"
                        : "none",
                  }}
                >
                  {currentProduct.attributes.submission_status === true
                    ? this.translationEvent(`${configJSON.nextItemTxt}`)
                    : this.translationEvent(`${configJSON.skipProductTxt}`)}
                </Button>

                <Button
                  className={
                    currentProduct.attributes.submission_status === false
                      ? `primary-btn ${classes.primaryButton}`
                      : `primary-btn-block ${classes.primaryButton}`
                  }
                  data-test-id="handle-submit"
                  onClick={() => this.handleSubmitAdditionalDetails()}
                >
                  {this.translationEvent(`Submit & Next`)}
                </Button>
              </Box>
            </Box>
          </Modal>
        )}
        {openOrderDetails && (
          <ViewOrderDetails
            id="viewDetails"
            navigation="viewDetails"
            //@ts-ignore
            classes={classes}
            handleCloseViewDetails={this.handleOrderDetailsClose}
            viewDetailsID={this.props.viewDetailsID}
            isViewDetails={openOrderDetails}
            data-test-id="viewDetails"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default FillOrderDetails;
// Customizable Area End
