import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
  makeStyles,
  Button,
  Popover
} from '@material-ui/core';
import { starIcon } from "../../blocks/customisableuserprofiles/src/assets";
import { Link, NavLink } from "react-router-dom";
import ConfirmActionModal from "../../blocks/email-account-registration/src/teams-web/ConfirmActionModal.web";
import { createCommonToastNotification } from './ReusableFunctions';


type IconName = 'User' | 'Home' | 'Users' | 'GitBranch' | 'BarChart' | 'Target' | 'HelpCircle' | 'Star' | 'ChevronDown';

const iconUrls = {
  User: 'https://fonts.gstatic.com/s/i/materialicons/person/v14/24px.svg',
  Home: 'https://fonts.gstatic.com/s/i/materialicons/home/v11/24px.svg',
  Users: 'https://fonts.gstatic.com/s/i/materialicons/group/v14/24px.svg',
  GitBranch: 'https://fonts.gstatic.com/s/i/materialicons/account_tree/v10/24px.svg',
  BarChart: 'https://fonts.gstatic.com/s/i/materialicons/bar_chart/v12/24px.svg', // Updated URL
  Target: 'https://fonts.gstatic.com/s/i/materialicons/gps_fixed/v11/24px.svg',
  HelpCircle: 'https://fonts.gstatic.com/s/i/materialicons/help_outline/v12/24px.svg',
  // Star: 'https://fonts.gstatic.com/s/i/materialicons/star/v13/24px.svg',
  ChevronDown: 'https://fonts.gstatic.com/s/i/materialicons/expand_more/v14/24px.svg'
};

// IconComponent definition with proper types
interface IconComponentProps {
  iconName: any;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}

const IconComponent: React.FC<IconComponentProps> = ({ iconName, size = 24, color = 'currentColor', style }) => (
  <img
    src={iconName}
    alt={iconName}
    width={size}
    height={size}
    style={{ fill: color, ...style }}
  />
);

const useStyles = makeStyles((theme) => ({

  button: {
    textTransform: 'none',
    justifyContent: 'space-between',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popoverPaper: {
    width: 300,
    borderRadius: theme.shape.borderRadius * 2,
    marginTop: theme.spacing(1),
  },
  listItem: {
    '&:last-child': {
      borderBottom: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: '#f0f7ff',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    "& .header-t1": {
      fontSize: '16px',
      color: "#2B2B2B",
      fontWeight: 400,
      "& .MuiListItemText-secondary ": {
        fontSize: '10px',
        color: '#94A3B8',
        fontWeight: 400,
      },
    },
  },

  helpSection: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    color: "#4BA3B7 !important",
    textDecoration: 'underline'
  },
  helpText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const roles = [
  { name: 'Task Owner', description: 'Setting a productive day', icon: iconUrls['User'] },
  { name: 'Owner', description: 'Global view of the company', icon: iconUrls['Home'] },
  { name: 'Manager', description: 'Lead your team to success', icon: iconUrls['Users'] },
  { name: 'Project Lead', description: 'Guide the vision and turn plans into reality', icon: iconUrls['GitBranch'] },
  { name: 'Marketing', description: 'Campaign performance', icon: iconUrls["BarChart"] },
  { name: 'Sales', description: 'Sales excellence through deals and contacts', icon: iconUrls['Target'] },
];


const RoleSelectionDropdown = () => {
  const classes = useStyles();
  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [link, setLink] = useState('/dashboard');
  const [isRoleChnage, setIsRoleChnage] = useState(false);

  const [roleBefore, setRoleBefore] = useState(roles[0]);


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setLink('/')
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmRoleChange = () => {
    setIsRoleChnage(!isRoleChnage);
  };

  const handleRoleSelect = (role: any) => {
    console.log('role',role)
    setRoleBefore(role)
    confirmRoleChange();
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ fontFamily: 'Expletus Sans' }}>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={handleClick}
        endIcon={<IconComponent iconName={iconUrls.ChevronDown} size={20} />}
      >
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={link}
          onClick={handleClick}
        >
          {selectedRole.name}
        </NavLink>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: classes.popoverPaper,
        }}
      >
        <Paper elevation={0}>
          <List disablePadding>
            {roles.map((role) => (
              <ListItem
                key={role.name}
                button
                selected={role.name === selectedRole.name}
                onClick={() => handleRoleSelect(role)}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <IconComponent
                    iconName={role.icon}
                    size={24}
                    color={role.name === selectedRole.name ? '#1976d2' : '#757575'}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={role.name}
                  secondary={role.description}
                  className="header-t1"

                />
                {role.name === selectedRole.name && (
                  <IconComponent iconName={starIcon} size={16} color="#4BA3B7" style={{ marginLeft: 8 }} />
                )}
              </ListItem>
            ))}
          </List>
          <Box className={classes.helpSection}>
            <Typography
              variant="body2"
              className={classes.helpText}
            >
              Need Help?
            </Typography>
          </Box>
        </Paper>
      </Popover>

      {isRoleChnage && (
        <ConfirmActionModal
          isOpen={isRoleChnage}
          handleClose={() => setIsRoleChnage(!isRoleChnage)}
          modalConfirmAction={() => {
            setSelectedRole(roleBefore);
            setIsRoleChnage(!isRoleChnage);
            handleClose();
            createCommonToastNotification(`Focus set to Task ${roleBefore.name} ${'\u00A0'} ${'\u00A0'} ${'\u00A0'}   Undo`)
          }}
          modalMessage={`Would you like to change your main focus to the "${roleBefore.name}" dashboard?`}
          confirmBtnTxt={'Change'}
          modalHeading={'Change Main Focus'}
          cancelBtnTxt={"Cancel"}
          data-testid="confirmOption"
        />
      )}
    </Box>
  );
};

export default RoleSelectionDropdown;
