export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const sortby = require("../assets/tune-vertical.svg");
export const etohSupportImg = require("../assets/etohSupport.png");
export const DoubleTick = require("../assets/Double-tick.png");
export const information = require("../assets/information-outline.png");
export const doubleTickGray = require("../assets/double-tick-gray.png");
export const playIcon = require("../assets/Play-icon.png");
export const attachment = require("../assets/Path.png");
export const sendIcon = require("../assets/sendIcon.png");
export const dummySS = require("../assets/dummy-ss.png");
export const defaultView = require("../assets/rafiki.png");
export const close = require("../assets/close.svg");
export const browse = require("../assets/Frame 7030.png");
export const quickChatIcon = require("../assets/open-in-new.png");
export const noQuickChat = require("../assets/pana.png");
export const minus = require("../assets/minus.png");
export const invitemenberImg = require("../assets/rafiki.svg");
export const externalImage = require("../assets/Vector.svg");
export const PlusImage = require("../assets/plus-button.png");
