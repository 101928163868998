// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Popover,
  CardMedia,
  Switch
} from "@material-ui/core";
import FilterDropDown from "./FilterDropDown.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from "@material-ui/icons/Search";
import BackArrow from "@material-ui/icons/ArrowBack";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import TablePagination from "@material-ui/core/TablePagination";
import {
  accountPlus,
  accountMulti,
  deletIcon,
  handShake,
} from "../assets";

import CustomInputWeb from "../../../../components/src/CustomInput.web";
import EditContactWeb from "../EditContact.web";
import { configJSON } from "../ContactsListController.web";
import { Contact } from "../../../../components/src/ReusableEnums";
import {groupFormats} from "../../../../components/src/ReusableFunctions"

interface ContactsGroupsProps {
  classes: any;
  anchorEl: any;
  contactAnchorId: string;
  openContactPopover: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    id:any
  ) => void;
  closePopover: () => void;
  openCreateContact: any;
  closeCreateContact: any;
  searchValue: string;
  handleSearch: (e: any) => void;
  showAddContactToGroup: () => void;
  closeContactGroup: () => void;
  isShowEditContact: boolean;
  onEditContact: (id:any) => void;
  onCloseEditContact: () => void;
  handleBackBtn:()=> void;
  groupDetails:any;
  contactDetails:any;
  handleDeleteOpen:()=>void;
  handleRenameGroupOpen:()=>void;
  handleInputChange:(e:any)=>void;
  handleChecked:(e:any)=>void;
  filterCompany: any;
  filterBusinessCategories: any;
  filterShippingCountry: any;
  businessCategoryList:any;
  onChangeSort:(e:any)=>void;
  isSortFilter:boolean;
  sortedAndFilteredData:any;
  hasAnOpenDeal:boolean;
  hasInteractions:boolean;
  hasFavoriteProducts:boolean;
  hasNote:boolean;
  anchorElMore: any;
  handleOpenMenuMore: any;
  handleCloseMenuMore: any;
  handleOpenDeal:(e:any)=>void;
  t:any;
  countryDataList:any;
  selectedRowId?: string;
  sortValue?:string;
}

const ContactsGroups = (props: ContactsGroupsProps) => {
  const {
    classes,
    searchValue,
    handleSearch,
    showAddContactToGroup,
    closeContactGroup,
    anchorEl,
    contactAnchorId,
    openContactPopover,
    closePopover,
    isShowEditContact,
    onEditContact,
    onCloseEditContact,
    handleBackBtn,
    groupDetails,
    contactDetails,
    handleDeleteOpen,
    handleRenameGroupOpen,
    handleInputChange,
    handleChecked,
    filterCompany,
    filterBusinessCategories,
    filterShippingCountry,
    businessCategoryList,
    onChangeSort,
    hasAnOpenDeal,
    hasInteractions,
    hasFavoriteProducts,
    hasNote,
    anchorElMore,
    handleOpenMenuMore,
    handleCloseMenuMore,
    handleOpenDeal,
    sortedAndFilteredData,
    t,
    isSortFilter,
    countryDataList,
    selectedRowId,
    sortValue,
  } = props;
  const filteredContacts = groupDetails?.attributes?.contacts?.filter((contact:any) => contact?.data !== null); 
  const transformedBusinessCategoryData =  businessCategoryList.map((item:any) => ({
    id: item.id,
    name: item.name,
  }));
  const uniqueCompanyNames = new Set();
  const uniqueContacts = groupDetails?.attributes?.contacts.filter((contact:Contact) => {
    const companyName = contact?.data?.attributes?.business_info?.data?.attributes?.company_name?.trim();
    if (companyName && !uniqueCompanyNames.has(companyName)) {
      uniqueCompanyNames.add(companyName);
      return true;
    }
    return false;
  });
  
  const companyNames = Array.from(
    new Set(
      uniqueContacts?.map((contact:Contact) => ({
          id: contact.data.id,
          name: contact.data.attributes.business_info.data.attributes.company_name.trim(),
        }))
        .filter((company:{name:string}) => company.name !== "")
    )
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const countryList = countryDataList.map((item:any) => ({
    id: item.id,
    name: item.country_name,
  }));

  const getTotalCount = () => {
    if(isSortFilter){
      return sortedAndFilteredData.length
    }else{
      return groupDetails?.attributes?.contacts?.length
    }
  }

  

  

  const showDefaultData = () => {
    const sortedContactsDefault = filteredContacts?.sort((a: any, b: any) => {
      const nameA = a?.data?.attributes?.first_name?.toLowerCase() || '';
      const nameB = b?.data?.attributes?.first_name?.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  
    const groupedContactsDefault: { [key: string]: any[] } = {};
    sortedContactsDefault?.forEach((item: any) => {
      const firstLetter = item?.data?.attributes?.first_name?.charAt(0)?.toUpperCase() || '#';
      if (!groupedContactsDefault[firstLetter]) {
        groupedContactsDefault[firstLetter] = [];
      }
      groupedContactsDefault[firstLetter].push(item);
    });
  
    const groupedArrayDefault = Object.keys(groupedContactsDefault).reduce((acc: any[], letter) => {
      const group = groupedContactsDefault[letter].map((item: any) => ({
        letter,
        item,
      }));
      return [...acc, ...group];
    }, []);
  
    const paginatedDataDefault = groupedArrayDefault.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
    return (
      <>
        {paginatedDataDefault.map(({ letter, item }: any, index: number) => (
          <React.Fragment key={item?.data?.id}>
            {index === 0 || paginatedDataDefault[index - 1]?.letter !== letter ? (
              <Typography className="tb-sort-character">{letter}</Typography>
            ) : null}
  
            {/* Display the item directly without iterating over groupedContactsDefault[letter] again */}
            <Box className="tb-row" key={item?.data?.id}>
              <Box className="tb-cell tb-cell-1">
                <Box className="avatar-wrapper">
                  <Typography className="text-eh">
                    {item?.data?.attributes?.first_name?.charAt(0).toUpperCase()}
                    {item?.data?.attributes?.last_name?.charAt(0).toUpperCase()}
                  </Typography>
                </Box>
                <Typography className="tb-cell-content">
                  {`${item?.data?.attributes?.first_name || ''} ${item?.data?.attributes?.last_name || ''}`}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-2">
                <Typography className="tb-cell-content">
                  {item?.data?.attributes?.email}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-3">
                <Typography className="tb-cell-content">
                  {groupFormats(item?.data?.attributes?.contact_groups)}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-3">
                <Typography className="tb-cell-content">
                  {item?.data?.attributes?.business_info?.data?.attributes?.company_name}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-3 large-col">
                <Typography className="tb-cell-content">
                  {item?.data?.attributes?.business_info?.data?.attributes?.business_category_name}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-3 large-col">
                <Typography className="tb-cell-content">
                  {item?.data?.attributes?.shipping_address?.data?.attributes?.country}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-4 large-col">
                <Typography className="tb-cell-content">
                  {item?.data?.attributes?.contact_products}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-5">
                <Typography className="tb-cell-content">
                  {item?.data?.attributes?.contact_interactions}
                </Typography>
              </Box>
              <Box className="tb-cell tb-cell-6">
                <Typography className="tb-cell-content">
                  {item?.data?.attributes?.contact_deals}
                </Typography>
              </Box>
              {showEditOptions(item?.data?.id)}
            </Box>
          </React.Fragment>
        ))}
      </>
    );
  };
  


  const showSortedData = () => {
    const groupedContacts: { [key: string]: any[] } = {};
    sortedAndFilteredData.forEach((contact: any) => {
      let firstLetter = "";
      if(sortValue === "Last Name"){
        firstLetter = contact?.attributes?.last_name?.charAt(0)?.toUpperCase() || '#';
      }else{
        firstLetter = contact?.attributes?.first_name?.charAt(0)?.toUpperCase() || '#';
      }
      if (!groupedContacts[firstLetter]) {
        groupedContacts[firstLetter] = [];
      }
      groupedContacts[firstLetter].push(contact);
    });
    const groupedArray = Object.keys(groupedContacts).reduce((acc: any[], letter) => {
      const group = groupedContacts[letter].map((contact: any) => ({
        letter,
        contact,
      }));
      return [...acc, ...group];
    }, []);
  
    const paginatedData = groupedArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
    return paginatedData.map(({ letter, contact }: any, index: number) => {
      const groups: any = contact?.attributes?.contact_groups;
      const name = `${contact?.attributes?.first_name} ${contact?.attributes?.last_name}`;
      const displayedGroups = groups.slice(0, 4);
      const filteredGroups = displayedGroups.filter((group: any) => group !== null);
      const groupNames = filteredGroups.map((group: any) => group?.name).join(', ');
      const ellipsis = groups.length > 3 ? '...' : '';
  
      return (
        <React.Fragment key={contact?.id || index}>
          {index === 0 || paginatedData[index - 1]?.letter !== letter ? (
            <Typography className="tb-sort-character">{letter}</Typography>
          ) : null}
  
          <Box className="tb-row" key={contact?.id}>
            <Box className="tb-cell tb-cell-1">
              <Box className="avatar-wrapper">
                <Typography className="text-eh">
                  {contact?.attributes?.first_name?.charAt(0)?.toUpperCase()}
                  {contact?.attributes?.last_name?.charAt(0)?.toUpperCase()}
                </Typography>
              </Box>
              <Typography className="tb-cell-content">{name}</Typography>
            </Box>
            <Box className="tb-cell tb-cell-2">
              <Typography className="tb-cell-content">{contact?.attributes?.email}</Typography>
            </Box>
            <Box className="tb-cell tb-cell-3">
              <Typography className="tb-cell-content">
                {groupFormats(contact?.attributes?.contact_groups)}
              </Typography>
            </Box>
            <Box className="tb-cell tb-cell-3">
              <Typography className="tb-cell-content">
                {contact?.attributes?.business_info?.data?.attributes?.company_name}
              </Typography>
            </Box>
            <Box className="tb-cell tb-cell-3">
              <Typography className="tb-cell-content">
                {contact?.attributes?.business_info?.data?.attributes?.business_category_name}
              </Typography>
            </Box>
            <Box className="tb-cell tb-cell-3">
              <Typography className="tb-cell-content">
                {contact?.attributes?.shipping_address?.data?.attributes?.country}
              </Typography>
            </Box>
            <Box className="tb-cell tb-cell-4">
              <Typography className="tb-cell-content">
                {contact?.attributes?.contact_products_count}
              </Typography>
            </Box>
            <Box className="tb-cell tb-cell-5">
              <Typography className="tb-cell-content">
                {contact?.attributes?.contact_interactions_count}
              </Typography>
            </Box>
            <Box className="tb-cell tb-cell-6">
              <Typography className="tb-cell-content">
                {contact?.attributes?.contact_deals_count}
              </Typography>
            </Box>
            {showEditOptions(contact?.id)}
          </Box>
        </React.Fragment>
      );
    });
  };
  
 const showEditOptions = (itemId: string) => {
    return (
      <Box className="tb-cell tb-cell-7">
      <Button className="btn-view-action" data-test-id="btn-view-action" onClick={()=>onEditContact(itemId)}>
      {t(`${configJSON.edit}`)}
      </Button>
      <Box>
        <Button 
          className="btn-view-action second-action-btn"
          onClick={(e:any)=>openContactPopover(e,itemId)}
          data-test-id="btn-view-action-more"
        >
          {t(`${configJSON.more}`)}
        </Button>
        <Popover
          open={Boolean(anchorEl) && selectedRowId === itemId}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right", 
          }}
          classes={{ paper: `${classes.popoverStyle}` }}
          PaperProps={{
            style: {
              boxShadow: selectedRowId === itemId ? "0px 16px 48px 0px rgba(0, 0, 0, 0.22)" : "none",

            },
          }}
        >
          <Box className={classes.contactMenuItem}>
            <Box
              className="menu-item"
              onClick={()=>{
                showAddContactToGroup()
                closePopover()
              }}
              data-test-id="menu-item"
            >
              <Typography>{t(`${configJSON.addToAnotherGroup}`)}</Typography>
              <CardMedia
                component="img"
                className="menu-icon"
                src={accountPlus}
              />
            </Box>
            <Box className="menu-item"
            onClick={()=>{
              handleRenameGroupOpen()
              closePopover()
            }}
            data-test-id="menu-item-remove"
            >
              <Typography>{t(`${configJSON.removeFromGroup}`)}</Typography>
              <CardMedia
                component="img"
                className="menu-icon"
                src={accountMulti}
              />
            </Box>
            <Box className="menu-item" 
            onClick={()=>{
              handleDeleteOpen()
              closePopover()
            }}
            data-test-id="menu-item-delete"
            >
              <Typography>{t(`${configJSON.deleteContact}`)}</Typography>
              <CardMedia
                component="img"
                className="menu-icon"
                src={deletIcon}
              />
            </Box>
            <Box className="menu-item"
             onClick={(event:any)=>{
              handleOpenDeal(event)
              closePopover()
            }}
            data-test-id="menu-item-deal"
            >
              <Typography>{t(`${configJSON.createDeal}`)}</Typography>
              <CardMedia
                component="img"
                className="menu-icon"
                src={handShake}
              />
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
    )
  }
  return (
    <>
      <Box className={classes.groupViewWrapper}>
        <Box className="contacts-group-heading">
          <Box className="back" onClick={handleBackBtn}>
            <BackArrow style={{color:"#2B2B2B"}}/>
            <Typography className="back-txt">{t(`${configJSON.back}`)}</Typography>
          </Box>
          <Typography className="group-name">{groupDetails?.attributes?.name}</Typography>
        </Box>
        <Box className="filter-box">
          <Box className="left-box group-contact-left-box">
            <CustomInputWeb
              value={searchValue}
              type="search"
              label=""
              onChange={(event: any) => handleSearch(event.target.value)}
              placeholder={t(`${configJSON.searchTxt}`)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{color:"rgb(132, 132, 132)"}} /> 
                </InputAdornment>
              }
              data-test-id="searchinput"
            />
          </Box>
          <Box className="center-box">
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.company}`)}
            options={companyNames}
            value={filterCompany}
            name="filterCompany"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown1"
          />
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.businessCategorytxt}`)}
            options={transformedBusinessCategoryData}
            value={filterBusinessCategories}
            name="filterBusinessCategories"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown2"
          />
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.shippingCountry}`)}
            options={countryList}
            value={filterShippingCountry}
            name="filterShippingCountry"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown3"
          />
            <Box className="filter-more">
            <Box className="menu-dropdown more" onClick={handleOpenMenuMore}>
              <Typography className="more-txt">{t(`${configJSON.more}`)}</Typography>
              <ArrowDropDownIcon style={{color:"#737070",marginTop:"-3px"}}/>
            </Box>
            <Popover
              open={Boolean(anchorElMore)}
              onClose={handleCloseMenuMore}
              anchorEl={anchorElMore}
              anchorOrigin={{
                horizontal: "center",
                vertical: "bottom",
              }}
              transformOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
             classes={{ paper: `${classes.popoverStyle}` }}
            >
              <Box className="view-more-dropdown">
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.openDeal}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    onChange={handleChecked} 
                    checked={hasAnOpenDeal}
                    name="hasAnOpenDeal"               
                   />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.openInteraction}`)}
                  </Typography>
                  <Switch
                    checked={hasInteractions}
                    className="dropdown-menu-item-switch"
                    onChange={handleChecked} 
                    name="hasInteractions"
                  />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.openFavProduct}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    onChange={handleChecked} 
                    checked={hasFavoriteProducts}
                    name="hasFavoritesProduct"
                  />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.hasNote}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    onChange={handleChecked} 
                    checked={hasNote}
                    name="hasNotes"
                  />
                </Box>
              </Box>
            </Popover>
          </Box>
          </Box>
          <Box className="right-box">
            <Box className="groups-paging">
            <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={getTotalCount()}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-test-id="table-pagination"
          />
            </Box>
            <FormControl className="select-control sortby-control">
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                onChange={(e:any) => onChangeSort(e.target.value)}
                displayEmpty
                defaultValue="Sort by"
                renderValue={(value: any) => {
                      return <Box>{value}</Box>;
                    }}
                IconComponent={() => (
                     <ArrowDropDownIcon style={{color:"rgb(120 118 118)"}}/>
                )}
                data-test-id="sort-by-dropdown"
              >
               
              <MenuItem value={t(`${configJSON.firstNameTxt}`)}>{t(`${configJSON.firstNameTxt}`)}</MenuItem>
              <MenuItem value={t(`${configJSON.lastNameTxt}`)}>{t(`${configJSON.lastNameTxt}`)}</MenuItem>
              <MenuItem value={t(`${configJSON.recentlyAddedTxt}`)}>{t(`${configJSON.recentlyAddedTxt}`)}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className="content-wrapper">
          <Box className="table-header">
            <Box className="hd-column hd-column-1">
              <Typography className="tb-header-title">{t(`${configJSON.name}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-2">
              <Typography className="tb-header-title">{t(`${configJSON.email}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-3">
              <Typography className="tb-header-title">{t(`${configJSON.group}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-3">
              <Typography className="tb-header-title">{t(`${configJSON.company}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-3 large-col">
              <Typography className="tb-header-title">{t(`${configJSON.businessCategorytxt}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-3 large-col">
              <Typography className="tb-header-title">{t(`${configJSON.shippingCountry}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-4 large-col">
              <Typography className="tb-header-title">
              {t(`${configJSON.favProduct}`)}
              </Typography>
            </Box>
            <Box className="hd-column hd-column-5">
              <Typography className="tb-header-title">{t(`${configJSON.interactions}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-5">
              <Typography className="tb-header-title">{t(`${configJSON.openDealTxt}`)}</Typography>
            </Box>
            <Box className="hd-column hd-column-6">
              <Typography className="tb-header-title">{t(`${configJSON.action}`)}</Typography>
            </Box>
          </Box>
          <Box className="table-content">
            {isSortFilter ? <>{showSortedData()}</> : <>{showDefaultData()}</>}            
          </Box>
          
        </Box>
      </Box>
      {isShowEditContact && (
      <EditContactWeb
      isShowEditContact={isShowEditContact} 
      handleOpenEditContact={onEditContact}
      viewSingleDetails={contactDetails}        
      hancleCloseEditContact={onCloseEditContact}
      />
      )}
    </>
  );
};

export default ContactsGroups;

// Customizable Area End
