// Customizable Area Start
import React from "react";

import { Box, Button, Typography, CardMedia, Popover,MenuItem,Menu } from "@material-ui/core";

import { contactListEmpty, expand, close } from "./assets";
import { customStyles } from "./ContactsListStyles.web";
import { withStyles } from "@material-ui/core/styles";
import ContactsGroup from "./components/ContactsGroup.web"; 
import CreateContactWeb from "./CreateContact.web";
import AddGroupToContact from "./components/AddGroupToContact.web";

import ViewContactsGroupController, {
  Props, configJSON,
} from "./ViewContactsGroupController.web";
import DeleteContact from "./DeleteContact.web";
import RemoveContact from "./components/RemoveContactFromGroup.web";
import AddAnExistingContact from "../../../components/src/AddAnExistingModal.web"
import CreateDealWeb from "./CreateDeal.web";
import GroupContacts from "./components/GroupContacts.web";
import WebLoader from "../../../components/src/WebLoader.web";
export class ViewContactsGroup extends ViewContactsGroupController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    
    return (
      <>
      {this.state.isLoading && <WebLoader/>}
      <Box className={classes.mainWrapper}>
        <Box className="page-wrapper">
          <Box className="main-content">
            {this.state.contacts.length > 0 ? (
              <ContactsGroup
                classes={classes}
                openCreateContact={this.handleOpenCreateContact}
                closeCreateContact={this.handleCloseCreateContact}
                searchValue={this.state.searchKeyword}
                handleSearch={this.handleSearch}
                anchorEl={this.state.contactAnchor}
                contactAnchorId={this.state.contactAnchorId}
                showAddContactToGroup={this.handleShowContactGroup}
                closeContactGroup={this.handleCloseContactGroup}
                openContactPopover={this.handleOpenContactPopover}
                closePopover={this.handleCloseContactPopover}
                isShowEditContact={this.state.isShowEditContact}
                onEditContact={this.onEditContact}
                onCloseEditContact={this.onCloseEditContact}
                handleBackBtn={this.handleBackBtn}
                groupDetails={this.state.groupDetails}
                contactDetails={this.state.contactDetails}
                handleDeleteOpen={this.handleDeleteOpen}
                handleRenameGroupOpen={this.handleRenameGroupOpen}
                handleInputChange={this.handleInputChange}
                handleChecked={this.handleChecked}
                filterCompany={this.state.filterCompany}
                filterBusinessCategories={this.state.filterBusinessCategories}
                filterShippingCountry={this.state.filterShippingCountry}
                businessCategoryList={this.state.businessCategoryList}
                onChangeSort={this.onChangeSort}
                isSortFilter={this.state.isSortFilter}
                sortedAndFilteredData={this.state.sortedAndFilteredData}
                sortValue={this.state.sortValue}
                hasAnOpenDeal={this.state.hasAnOpenDeal}
                hasFavoriteProducts={this.state.hasFavoritesProduct}
                hasNote={this.state.hasNotes}
                hasInteractions={this.state.hasInteractions}
                anchorElMore={this.state.anchorElMore}
                handleOpenMenuMore={this.handleOpenMoreMenu}
                handleCloseMenuMore={this.handleCloseMoreMenu}
                handleOpenDeal={this.handleOpenDeal}
                countryDataList={this.state.countryDataList}
                selectedRowId={this.state.selectedRowId}
                t={this.t}
                data-test-id="contact-group"
              />
            ) : (
              <Box className="empty-box">
                <CardMedia
                  component="img"
                  className="img-contact-book"
                  image={contactListEmpty}
                />
                <Box className="box-empty-right">
                  <Typography className="txt-no-contact-title">
                    {this.t(`${configJSON.noContactCreatedTxt}`)}
                  </Typography>
                  <Typography className="txt-no-contact-sub-title">
                    {this.t(`${configJSON.createContactDefaultMsg}`)}
                  </Typography>
                  <Button>+ {this.t(`${configJSON.newContact}`)}</Button>
                </Box>
              </Box>
            )}

            {this.state.floatCreateContact && (
              <Box className="btn-minimize-create-contact">
                <CardMedia
                  component="img"
                  src={expand}
                  className="expand-icon"
                  onClick={this.openCreateContactPopoverShown}
                  data-test-id="openCreateContactPopoverShown"
                />
                <Typography className="create-txt">+ {this.t(`${configJSON.createContact}`)}</Typography>
                <Button onClick={this.closeFloatCreateContact} data-test-id="closeFloatCreateContact">
                  <CardMedia
                    component="img"
                    src={close}
                    className="close-icon"
                  />
                </Button>
              </Box>
            )}

            <Box className="create-contact-wrapper">
              <Button
                className="btn-create-contact"
                onClick={this.handleOpnAddContactMenu}
                data-test-id="handleOpnAddContactMenu"
              >
                + {this.t(`${configJSON.addContact}`)}
              </Button>
              <Menu
                id="simple-menu"
                          anchorEl={this.state.openAddContcatMenu}
                          keepMounted
                          open={Boolean(this.state.openAddContcatMenu)}
                          onClose={this.handleCloseAddContactMenu}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                          className={classes.dropDropdown}
                        >
                          <MenuItem onClick={() => {
                           this.handleOpenAddAnExisting()
                           this.handleCloseAddContactMenu()
                          }} data-test-id="menu-item-1">
                                                      
                           {this.t(`${configJSON.addAnExistingContactTxt}`)}
                          </MenuItem>
                          <MenuItem onClick={(event: any) => {
                           this.handleOpenCreateContact(event)
                           this.handleCloseAddContactMenu()
                          }} data-test-id="menu-item-2">                           
                           {this.t(`${configJSON.addNewContactTxt}`)}
                    </MenuItem>
                 </Menu>
              <Popover
                open={
                  this.state.createContactPopoverShown &&
                  Boolean(this.state.anchorEl)
                }
                anchorEl={this.state.anchorEl}
                onClose={this.handleCloseCreateContact}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={classes.popover}
                style={{ bottom: "100px" }}
              >
                <CreateContactWeb
                  id="add-new-contact"
                  navigation="/group-contacts"
                  handleCloseDialog={this.handleCloseCreateContact}
                  handleMinimizeCreateContact={this.openFloatCreateContact}
                  data-test-id="create-contact"
                />               
              </Popover>
            </Box>

            {this.state.isAddAnExisting&& (
              <AddAnExistingContact 
                classes={classes}
                modalOpen={this.state.isAddAnExisting}
                modalClose={this.handleCloseAddAnExisting}
                title={this.t(`${configJSON.addAnExistingContactTxt}`)} 
                subTitle={this.t(`${configJSON.addAnExistingSubText}`)} 
                contacts={this.state.addAnExistingData}
                searchContactKeyword={this.state.searchContactKeyword}
                handleSearchContact={this.handleSearchContact}
                isSearching={this.state.isContactSearching}
                searchedContacts={this.state.searchedContacts}
                handleSortContact={this.handleSortContact}
                isSorting={this.state.isSorting}
                sortedContacts={this.state.sortedAndFilteredData}
                showMoreGroups={this.state.showMoreGroups}
                toggleShowGroups={this.toggleShowGroups}
                onClickEvent={this.getAddExistingContcat}
                data-test-id="add-an-existing-contact"
                />
            )}

            {this.state.isShowContactGroup && (
              <AddGroupToContact
                classes={classes}
                modalOpen={this.state.isShowContactGroup}
                modalClose={this.handleCloseContactGroup}
                searchGroup={this.state.searchGroupKeyword}
                handleSearchGroup={this.handleSearchGroup}
                groups={this.state.groups}
                addToGroup={this.addToGroupAction} 
                contsctDetails={this.state.contactDetails}
                t={this.t}
                type="viewGroup"
                data-test-id="add-to-group"
                openGroupState={this.state.openGroup}
                openGroup={this.handleOpenGroup}
                closeGroup={this.handleCloseGroup}
              />
            )}
             {this.state.isDelete && (
              <DeleteContact
                modalOpen={this.state.isDelete}
                modalClose={this.handleDeleteClose}
                delContactDetails={this.state.deleteContactDetails}
                deleteContactAction={this.deleteContactFromGroupAction}
                headding={this.t(`${configJSON.deleteContactTxt}`)}
                cancleBtntxt={this.t(`${configJSON.cancel}`)}
                deleteBtntxt={this.t(`${configJSON.delete}`)}
                confirmationMsg={this.t(`${configJSON.deleteTxtMsgContact}`)}
                data-test-id="deleteContact"
              />
            )}
            {this.state.isRemoveGroup &&(
              <RemoveContact
                modalOpen={this.state.isRemoveGroup}
                modalClose={this.handleRenameGroupClose}
                delContactDetails={this.state.renameContactDetails}
                deleteContactAction={this.removefromGroupAction}
                headding={this.t(`${configJSON.removeFromGroupTxt}`)}
                cancleBtntxt={this.t(`${configJSON.cancel}`)}
                removeBtntxt={this.t(`${configJSON.remove}`)}
                data-test-id="removeContact"
              />
            )}
            {this.state.createDealPopoverOpen && (
            <Popover
              open={Boolean(this.state.anchorDeal)}
              anchorEl={this.state.anchorDeal}
              onClose={this.handleCloseDeal}
              anchorOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              classes={{ paper: `${classes.createDealsBox}`}}
              >
           <CreateDealWeb
            handleCloseDialog={this.handleCloseDeal} 
            classes={classes}
            handleSelectContactsOpen={this.handleSelectContactsOpen}
            data-test-id="contact-deal"
            />
          </Popover>
            )            
            }
          {this.state.selectContacts &&
            <GroupContacts
            classes={classes}
            modalOpen={this.state.selectContacts}
            modalClose={this.handleSelectContactsClose}
            searchContactKeyword={this.state.searchKeyword}
            handleSearchContact={this.handleSearch}
            contacts={this.state.contacts}
            // isSearching={this.state.isSearching}
            // isSorting={this.state.sortValue}
            handleSortContact={this.onChangeSort}
            searchedContacts={this.state.searchedContacts}
            sortedContacts={this.state.sortedAndFilteredData}
            subTitle={this.t(`${configJSON.chooseDealSubTitle}`)}
            data-test-id="groupContacts" 
            t={this.t}
            editGroupEvent={function (): void {
              throw new Error("Function not implemented.");
            } }/>
          }
          </Box>
        </Box>
      </Box>
      </>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(ViewContactsGroup);
// Customizable Area End
