import React from "react";
import { wineImg } from "./assets";
import { Box, Typography, Grid, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const products = [
  {
    id: 1,
    name: "Petite cave",
    price: 540.0,
    quantity: 10,
    retailPrice: 54.0,
    producer: "Spain",
    brand: "Sauvignon Blanc",
    color: "Red",
    appellation: "Australia",
    grapeVariety: "Cinsaut",
    vintage: 1992,
    imageUrl: wineImg,
  },
  {
    id: 2,
    name: "Petite cave",
    price: 540.0,
    quantity: 10,
    retailPrice: 54.0,
    producer: "Spain",
    brand: "Sauvignon Blanc",
    color: "Red",
    appellation: "Australia",
    grapeVariety: "Cinsaut",
    vintage: 1992,
    imageUrl: wineImg,
  },
  {
    id: 3,
    name: "Petite cave",
    price: 540.0,
    quantity: 10,
    retailPrice: 54.0,
    producer: "Spain",
    brand: "Sauvignon Blanc",
    color: "Red",
    appellation: "Australia",
    grapeVariety: "Cinsaut",
    vintage: 1992,
    imageUrl: wineImg,
  },
  // Add more products as needed
];

const useStyles = makeStyles((theme) => ({
  cardsmainWrapper: {
    padding: "20px",
    "& .summary-card": {
      border: "1px solid",
      borderColor: "#E1E1E1",
      borderRadius: "12px",
      padding: "15px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      height: "100%",
    },
    "& .summary-image-block": {
      flex: "0 0 56px",
      maxWidth: "56px",
      "& img": {
        width: "100% !important",
        margin: "0 !important",
      },
    },
    "& .summary-card-details": {
      flex: "1",
      width: "100%",
      paddingLeft: "15px",
      "& .summary-details-top": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        "& .item-info": {
          "& span": {
            color: "#94A3B8",
          },
        },
      },
      "& .product-list": {
        padding: 0,
        marginBottom: "20px",
        display: "flex",
        justifyContent: "flex-start",
        gap: "30px",
        "&:last-child": {
          marginBottom: "0",
        },
        "& li": {
          width: "auto",
          display: "inline-block",
          textAlign: "left",
          padding: "0",
          "& > p": {
            display: "flex",
            flexDirection: "column !important",
            fontSize: "14px",
            color: "#2b2b2b !important",
            fontWeight: "400 !important",
            fontFamily: "Roboto !important",
            "& strong": {
              fontSize: "12px !important",
              color: "#94A3B8 !important",
              fontWeight: "400 !important",
              textTransform: "capitalize !important",
              fontFamily: "Roboto !important",
            },
          },
        },
      },
    },
    "& .product-title": {
      fontFamily: "Expletus Sans",
      fontWeight: 500,
      fontSize: "14px",
    },
    "& .item-info": {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "10px",
    },
  },
}));

const ProductCardsSummary = () => {
  const classes = useStyles();
  return (
    <Box className={classes.cardsmainWrapper}>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Box className="summary-card">
              <Box className="summary-image-block">
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  style={{ width: "50px", marginRight: "15px" }}
                />
              </Box>
              <Box className="summary-card-details">
                <Box className="summary-details-top">
                  <Box>
                    <Typography className="product-title" variant="h6">
                      {product.name}
                    </Typography>
                    <Typography className="item-info" variant="body2">
                      {product.quantity} items @ ${product.retailPrice}{" "}
                      <span>Retail Price</span>
                    </Typography>
                  </Box>

                  <Typography className="product-title" variant="h6">
                    ${product.price.toFixed(2)}
                  </Typography>
                </Box>
                <List className="product-list">
                  <ListItem>
                    <Typography variant="body2">
                      <strong>Producer:</strong> {product.producer}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">
                      <strong>Brand:</strong> {product.brand}
                    </Typography>
                  </ListItem>
                </List>

                <List className="product-list">
                  <ListItem>
                    <Typography variant="body2">
                      <strong>Color:</strong> {product.color}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">
                      <strong>Appellation:</strong> {product.appellation}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">
                      <strong>Grape Variety:</strong> {product.grapeVariety}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2">
                      <strong>Vintage:</strong> {product.vintage}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductCardsSummary;
